import { useNavigate, Outlet, useNavigation } from 'react-router-dom';

function LayoutReport(){
    const navigation = useNavigation();
    const navigate = useNavigate(); 

    // const [isLoading, setIsLoading] = useState<boolean>(false);
    
    return (
        <>
            <div className={ navigation.state === "loading" ? "loading" : "" }></div>
            <nav id="main-navbar" className="navbar navbar-expand-md">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src="/images/logo-black.png" alt="Logo" height="64" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="d-flex ms-auto">
                            <button
                                className="btn btn-light me-3 back-button"
                                title="Instances from AWS"
                                onClick={() => {
                                    navigate("/home");
                                }}
                            ></button>
                        </div>
                    </div>
                </div>
            </nav>
            <main className="container-fluid px-0">
                <Outlet />
            </main>
        </>
    );
};

export default LayoutReport;
