import "../css/Chart.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { StackedBarChartData } from "./types"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const colors = [
  "#4caa49",
  "#7ed957",
  "#a4d4a4",
  "#4caa49",
  "#7ed957",
  "#a4d4a4",
  "#4caa49",
  "#7ed957",
  "#a4d4a4",
  "#4caa49",
  "#7ed957",
  "#a4d4a4"
];

type CustomTooltipProps = {
  active?: boolean;
  payload?: any[];
  label?: any;
};

type StackedBarChartProps = {
  chartData: StackedBarChartData[];
  title: string;
};

function StackedBarChart({ chartData, title }: StackedBarChartProps) {
  let tooltip: string;
  console.log('chartData en StackedBarChart', chartData)


  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    //if (!active || !tooltip) return null;
    if (!active || !payload || payload.length === 0 || !tooltip ) return null;
    if (active && payload && payload.length) {
      for (const bar of payload)
        if (bar.dataKey === tooltip) {
          return (
            <div className="custom-tooltip">
              <span className="custom-tooltip-label">{label}</span>
              <br />
              <span className="custom-tooltip-bar-name">{bar.name}:</span>
              <span>{bar.value.toLocaleString('es-ES', { useGrouping: true })}</span>
            </div>
          );
        }
    }
    return null;
  };

  return (
    <>
      <div className="chart-title">{title}</div>
      {/* <ResponsiveContainer> */}
      <div className="chart-container">
        <BarChart
          width={500}
          height={500}
          data={chartData}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 25
          }}
        >
          <CartesianGrid vertical={false} stroke="black" />
          <XAxis
            dataKey="year"
            tickMargin={5}
            tick={{ fill: "black", fontSize: 16 }}
          />
          <YAxis
            axisLine={false}
            tick={{ fill: "black", fontSize: 16 }}
            tickMargin={8}
            tickFormatter={tick => {
              //format the thousandths indicator on the numbers
              return tick.toLocaleString('es-ES', { useGrouping: true });
            }}
            // label={{
            //   value: 'Título del eje Y',
            //   position: 'top', // Alinear el título dentro del eje Y
            //   dx: 400, // Desplazamiento horizontal para centrar
            //   dy: -20, // Desplazamiento vertical para posicionar en la parte superior
            // }}
          />
          <Tooltip
            contentStyle={{
              background: "transparent",
              border: "none",
              boxShadow: "none"
            }}
            isAnimationActive={false}
            content={<CustomTooltip />}
            cursor={{fill: 'transparent'}}
            // coordinate={tooltipPosition}
          />
          {months.map((month, index) => (
            <Bar
              key={index}
              dataKey={month}
              stackId="a"
              barSize={60}
              // fill={colors[index]}
              fill={colors[index % colors.length]}
              onMouseOver={() => tooltip = month}
            />
          ))}

        </BarChart>
      </div>
      {/* </ResponsiveContainer> */}
    </>
    
  );
}

export default StackedBarChart;