import { ActionFunctionArgs, redirect } from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";

const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const installationType = formData.get("flexRadioDefault");
  const installationInstance = formData.get("installationInstance");
  const installationPackage = formData.get("installationPackage");
  const edumyThemeValue = formData.get("edumyThemeCheck");
  const installThemeEdumy =
    edumyThemeValue && edumyThemeValue === "edumyTheme" ? true : false;
  const totaraType = formData.get("totaraType");
  const client = formData.get("client");
  const contractedUsers = formData.get("contractedUsers");
  const branch = formData.get("branch");
  const domain = formData.get("instanceDomain");
  const instanceName = formData.get("instanceName");
  const instanceType = formData.get("instanceType");
  const dbinstance = formData.get("dbinstanceName");
  const volumeSize = formData.get("volumeSize");
  const moodleInstallationType = formData.get("moodleInstallationType");
  const rdsStandaloneValue = formData.get("rdsStandaloneCheck");
  const rdsStandalone = 
    rdsStandaloneValue && rdsStandaloneValue === "rdsStandalone" ? true : false;
  const dbOnSharedRDS = !rdsStandalone;

  console.log("rdsStandaloneValue: ", rdsStandaloneValue);
  console.log("rdsStandalone: ", rdsStandalone)
  console.log("dbOnSharedRDS: ", dbOnSharedRDS)
  console.log("installationType: ", installationType);
  console.log("installationInstance: ", installationInstance);
  console.log("installationPackage: ", installationPackage);
  console.log("edumyThemeValue: ", edumyThemeValue);
  console.log("installThemeEdumy: ", installThemeEdumy);
  console.log("totaraType: ", totaraType);
  console.log("client: ", client);
  console.log("contractedUsers: ", contractedUsers);
  console.log("branch: ", branch);
  console.log("domain: ", domain);
  console.log("instanceName: ", instanceName);
  console.log("instanceType: ", instanceType);
  console.log("dbinstance: ", dbinstance);
  console.log("volumeSize: ", volumeSize);
  console.log("moodleInstallationType: ", moodleInstallationType);

  try {
    const response = await api.post(`/instance`, {
      installationType: installationType,
      installationInstance: installationInstance,
      installationPackage: installationPackage,
      installThemeEdumy: installThemeEdumy,
      totaraType: totaraType,
      client: client,
      contractedUsers: contractedUsers,
      branch: branch,
      domain: domain,
      instanceName: instanceName,
      instanceType: instanceType,
      dbInstance: dbinstance,
      volumeSize: volumeSize,
      moodleInstallationType: moodleInstallationType,
      dbOnSharedRDS: dbOnSharedRDS,
    });

    const data = response.data;

    if (data.success) {
      console.log("response ok: ", data);
      if (data.startCreationProcess && data.deployId && data.deployId !== ''){
        const deployId = data.deployId;
        return { 
          error: false, 
          startCreationProcess: true, 
          deployId: deployId 
        };
      }
      else {
        const message = 
          data.message ? data.message : "No information has been received about the process. Please contact system support.";
        alert(message);
        return { 
          error: false, 
          message: message, 
          startCreationProcess: false, 
          deployId: "",
          blockCreation: data.blockCreation ? data.blockCreation : false
        };
      }
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const response = error.response;
      const { data } = response;

      //not authorized
      if (response.status === 401) return redirect("/");

      //incorrect parameters
      if (response.status === 400 && data.fieldsError) {
        return {
          error: true,
          fieldsErrors: data.fields,
          startCreationProcess: false,
          deployId: "",
        };
      }

      //another error
      alert(data.message);
      return {
        error: true,
        message: data.message,
        startCreationProcess: false,
        deployId: "",
      };
    }
  }
};

export default action;
