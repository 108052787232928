import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api } from "../../api";
import { useGlobalContext } from "../../GlobalContext";
import { dbInstanceType, dbInstanceData } from "./types";

interface DBInstanceRowProps {
  dbinstance: dbInstanceType;
}

let dbinstancesCache: any = {};

const DBInstanceRow: FC<DBInstanceRowProps> = ({ dbinstance }) => { 
  const { region } = useGlobalContext();

  const [usageStorage, setUsageStorage] = useState<number>(0);

  useEffect(() => {
    const url = `/dbinstances/${dbinstance.dbInstanceId}/data?region=${region}`;
    const updateState = (data: dbInstanceData) => {
      //console.log(`${dbinstance.dbInstanceId}`, data)
      if (data.freeStorage && dbinstance.storageGiB) {
        const usageStorageGiB = dbinstance.storageGiB - data.freeStorage;
        setUsageStorage(usageStorageGiB);
        dbinstance.usageStorageGiB = usageStorageGiB;
      }
    };

    // Check if dbinstance is not available
    if (dbinstance.state !== "available") return;

    if (dbinstancesCache[url]) return updateState(dbinstancesCache[url]);

    if (dbinstance.state === "available") {
      const controller = new AbortController();
      api
        .get<dbInstanceData>(url, { signal: controller.signal })
        .then(({ data }) => {
          dbinstancesCache[url] = data;
          updateState(data);
        })
        .catch(() => {});

      return () => {
        controller.abort();
      };
    }
  }, [dbinstance, region]); 
  

  return (
    <tr>
      <th scope="row" className={dbinstance.state}>{dbinstance.state}</th>
      <td>{dbinstance.dbInstanceId}</td>
      <td>{dbinstance.dbInstanceClass}</td>
      <td>{dbinstance.engine} {dbinstance.engineVersion}</td>
      <td>{dbinstance.storageGiB} GiB</td>
      <td>{ (usageStorage && usageStorage !== 0) 
            ? `${usageStorage} GiB` 
            : ""}
      </td>
      <td>
        <div className="mt-2">
          <Link title="Actions" className="options-table-instances actions-icon" to={`actions/${dbinstance.dbInstanceId}`}></Link>
          <Link title="DBSnapshots" className="options-table-instances dbsnapshots-icon" to={`dbsnapshots/${dbinstance.dbInstanceId}`}></Link>
          {dbinstance.deployment === "shared" &&
            <Link title="Information" className="options-table-instances information-icon" to={`information/${dbinstance.dbInstanceId}`}></Link>
          }
        </div>
      </td>
    </tr>

  );
}

export default DBInstanceRow;
