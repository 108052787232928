import { redirect } from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";
import { SiloType } from "./types";

const loader = async () => {
  const region = window.localStorage.getItem("moodle-console-region");
  console.log(region);

  let result = {
    error: false,
    installers: [],
    silos: [],
    messageErrors: { installers: "", silos: "" },
  };

  try {
    const response = await api.get(`/instances/installers?region=${region}`);
    const data = response.data;
    if (data.success) result.installers = data.installers;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const response = error.response;
      const data = response.data;

      // if not authorized then redirect to login
      if (response.status === 401) return redirect("/");
      else {
        result.error = true;
        result.messageErrors.installers = data.message;
      }
    }
  }

  try {
    const response = await api.get(`/instances/silos?region=${region}`);

    const data = response.data;
    if (data.success) {
      if (data.silos && Array.isArray(data.silos) && data.silos.length > 0) {
        // Filters the elements that start with "MDL_MOODLE" but do not end in "03" or "04"
        const filteredSilos = data.silos
          .sort((a: SiloType, b: SiloType) => a.name.localeCompare(b.name))
          .filter((server: SiloType) =>
            server.name.startsWith('MDL_MOODLE') &&
            !server.name.endsWith('03') &&
            !server.name.endsWith('04')
          );
        result.silos = filteredSilos;
      } else {
        result.error = true;
        result.messageErrors.silos = "No silos available were found.";
      }
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const response = error.response;
      const data = response.data;

      // if not authorized then redirect to login
      if (response.status === 401) return redirect("/");
      else {
        result.error = true;
        result.messageErrors.silos = data.message;
      }
    }
  }

  return result;
};

export default loader;
