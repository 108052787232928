import { ActionFunctionArgs } from "react-router-dom";

const loader = async ({ params }: ActionFunctionArgs) => {
  const region = window.localStorage.getItem("moodle-console-region");
  const instanceId = params.instanceId;
  const instanceIp = params.instanceIp;
  const instanceLMS = params.instanceLMS;
  console.log(instanceId, instanceIp, instanceLMS, region);

  return {
    instanceId: instanceId,
    instanceIp: instanceIp,
    instanceLMS: instanceLMS,
  };
};

export default loader;
