import React from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import "../css/App.css";
import { useState, useEffect } from "react";
import { api } from "../../api";
import { AxiosError } from "axios";
import { InstanceDataType, PeriodType } from "./types";
// import { MonthlyBarChartData, DailyBarChartData, StackedBarChartData } from "../Chart/types";
import StackedBarChart from "../Chart/StackedBarChart";
import CustomBarChart from "../Chart/CustomBarChart";

// type ChartData = StackedBarChartData[] | MonthlyBarChartData[] | DailyBarChartData[];
let cache: any = {};

function Report() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStorageLoading, setIsStorageLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  
  const [instanceDataList, setInstanceDataList] = useState<InstanceDataType[]>([]);
  const [instanceSelected, setInstanceSelected] = useState<InstanceDataType>();

  const [periodSelected, setPeriodSelected] = useState<PeriodType|''>(PeriodType.GLOBAL);
  const [month, setMonth] = useState<number|undefined>(undefined);
  const [year, setYear] = useState<number|undefined>(undefined);

  const [chartData, setChartData] = useState<any>([]);
  const [storageChartData, setStorageChartData] = useState<any>([]);

  const localStorageRegion = window.localStorage.getItem(
    "moodle-console-region"
  );

  //To test whitout connection
/*   const data = [
    {
      year: 2021,
      Enero: 0,
      Febrero: 20,
      Marzo: 30,
      Abril: 20,
      Mayo: 10,
      Junio: 18,
      Julio: 20,
      Agosto: 55,
      Septiembre: 10,
      Octubre: 5,
      Noviembre: 12,
      Diciembre: 5
    },
    {
      year: 2022,
      Enero: 20,
      Febrero: 0,
      Marzo: 40,
      Abril: 20,
      Mayo: 15,
      Junio: 8,
      Julio: 10,
      Agosto: 5,
      Septiembre: 10,
      Octubre: 15,
      Noviembre: 2,
      Diciembre: 15,
      total: 90
    },
    {
      year: 2023,
      Enero: 60,
      Febrero: 17,
      Marzo: 0,
      Abril: 20,
      Mayo: 5,
      Junio: 8,
      Julio: 10,
      Agosto: 8,
      Septiembre: 10,
      Octubre: 5,
      Noviembre: 20,
      Diciembre: 15
    }
  ];

  const data6Months = [
    { month: 6, amount: 0 },
    { month: 7, amount: 32 },
    { month: 8, amount: 6 },
    { month: 9, amount: 9 },
    { month: 10, amount: 28 },
    { month: 11, amount: 13 }
  ]

  const data1Month = [
    { dayOfMonth: 2, amount: 2 },
    { dayOfMonth: 6, amount: 2 },
    { dayOfMonth: 8, amount: 1 },
    { dayOfMonth: 9, amount: 2 },
    { dayOfMonth: 14, amount: 2 },
    { dayOfMonth: 15, amount: 4 },
    { dayOfMonth: 24, amount: 3 }
  ] */

  const region = localStorageRegion ? localStorageRegion : "us-west-2"

  const navigation = useNavigation();
  const navigate = useNavigate();

  function handleInstanceChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const selectedIndex = e.target.selectedIndex;
    //console.log('selectedIndex: ', selectedIndex)
    const selectedOption = e.target.options[selectedIndex];
    //console.log('selectedOption: ', selectedOption)
    const selectedObject = selectedOption.getAttribute('data-object');
    //console.log("selectedObject: ", selectedObject)
  
    if (selectedObject) {
      const parsedObject = JSON.parse(selectedObject);
      //console.log("parsedObject: ", parsedObject);
      setInstanceSelected(parsedObject);
      setMonth(undefined)
      setYear(undefined);
      setPeriodSelected(PeriodType.GLOBAL);
    }
    else {
      setInstanceSelected(undefined);
    }
  }

  function handlePeriodChange(value: string) {
    if (value) {
      console.log('entre')
      setPeriodSelected(value as PeriodType);
      // Set default values for month and year depending on the period selected
      if (value as PeriodType === PeriodType.MONTHLY || value === PeriodType.SEMESTRAL) {
        console.log('entre en la condicion')
        console.log('mes', new Date().getMonth() + 1)
        console.log('año', new Date().getFullYear())
        setMonth(new Date().getMonth() + 1);
        //setMonth(8);// to test
        setYear(new Date().getFullYear());
      } else {
        setMonth(undefined)
        setYear(undefined);
      }
    }
    else{ // Clean the chart
      setPeriodSelected('');
      setChartData([]);
    }
  }

  function loadLoginsChartComponent() {
    let chartComponent;
  
    switch (periodSelected) {
      case PeriodType.GLOBAL:
        chartComponent = <StackedBarChart chartData={chartData} title=" Record of Logins" />;
        break;
      
      case PeriodType.MONTHLY:
        console.log('cantidad de dias: ', chartData.length)
        chartComponent = <CustomBarChart 
                          chartData={chartData} 
                          dataKeyFromXaxis="dayOfMonth"
                          tickOffsetX={-5} 
                          title=" Record of Logins" 
                          chartWidth={ chartData.length > 20 ? 800 : 500 } 
                          tooltipTitle="Day" 
                          tooltipLabel="Users" 
                          barSize={40} 
                          monthOfPeriodMonthly={month} 
                        />;
        break;
      
      case PeriodType.SEMESTRAL:
        chartComponent = <CustomBarChart 
                          chartData={chartData} 
                          dataKeyFromXaxis="month" 
                          tickOffsetX={-10}
                          title=" Record of Logins" 
                          chartWidth={500} 
                          barSize={50} 
                          tooltipLabel="Users" 
                        />;
        break;
      
      default:
        chartComponent = <p>Period is not valid.</p>;
    }
  
    return chartComponent;
  }

  function loadStorageChartComponent() {
    let chartComponent;
  
    switch (periodSelected) {
      case PeriodType.GLOBAL:
        chartComponent = <CustomBarChart 
                            chartData={storageChartData} 
                            title="Storage`s Use" 
                            chartWidth={ storageChartData.length > 5 ? 600 : 500 }
                            dataKeyFromXaxis="year" 
                            tickOffsetX={-18} 
                            barSize={ storageChartData.length > 5 ? 40 : 50 } 
                            // tooltipTitle="Year" 
                            tooltipLabel="Storage" 
                            yAxisLabel="MB" 
                          />;
        break;
      
      case PeriodType.MONTHLY:
        console.log('cantidad de dias: ', chartData.length)
        chartComponent = <CustomBarChart 
                          chartData={storageChartData} 
                          dataKeyFromXaxis="dayOfMonth" 
                          tickOffsetX={-5}
                          title="Storage`s Use" 
                          chartWidth={ storageChartData.length > 20 ? 800 : 500 } 
                          tooltipTitle="Day" 
                          tooltipLabel="Storage" 
                          barSize={40} 
                          monthOfPeriodMonthly={month} 
                          yAxisLabel="MB"  
                        />;
        break;
      
      case PeriodType.SEMESTRAL:
        chartComponent = <CustomBarChart 
                          chartData={storageChartData} 
                          dataKeyFromXaxis="month"
                          tickOffsetX={-10} 
                          title="Storage`s Use" 
                          chartWidth={500} 
                          barSize={50} 
                          tooltipLabel="Storage" 
                          yAxisLabel="MB" 
                        />;
        break;
      
      default:
        chartComponent = <p>Period is not valid.</p>;
    }
  
    return chartComponent;
  }

  function convertStorageToMB(data: any[]) {
    return data.map(item => {
      if (item.amount !== undefined) {
        return {
          ...item,
          amount: (parseFloat(item.amount) / 1024).toFixed(2)
        };
      }
      return item;
    });
  }

  useEffect(() => {
    console.log("useEffect de Report: ", region);
    let isSubscribed = true;
    setIsError(false);
    setErrorMessage("");

    setIsLoading(true);
     api
      .get(`/instances/data?region=${region}`)
      .then((response) => {
        const { data } = response;

        console.log("data recibida en useEffect: ", data);
        if (isSubscribed) {
          setInstanceDataList(data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        let message;

        if (err instanceof AxiosError && err.response) {
          let data = err.response.data;
          message = data.message;
        } else message = err.message;

        console.log(">>> [message]", message);
        setIsError(true);
        setErrorMessage(message);
        setIsLoading(false);
      });

    // cancel any future set state
    return () => {
      isSubscribed = false;
    };
  }, [region]);

  useEffect(() => {

    setIsError(false);
    setErrorMessage("");

    console.log("useEffect loading logins: ", instanceSelected?.name, periodSelected, month, year);
    const updateState = (data: any) => {
      setChartData(data);
    };
    const clearData = () => {
      setChartData([]);
      //setPeriodSelected('');
      // Set option TIMEFRAME to select period
      const selectTimeframe = document.getElementById('select-timeframe') as HTMLSelectElement;
      selectTimeframe.selectedIndex = 0;
    };

    if(instanceSelected && periodSelected ){

      const url=`/instances/${instanceSelected?.id}/${instanceSelected?.domain}/moodle-login-report?region=${region}&ip=${instanceSelected?.ip}&name=${instanceSelected?.name}&lms=${instanceSelected?.lms}&deployment=${instanceSelected?.deployment}&period=${periodSelected}&month=${month}&year=${year}`;
      if (cache[url]) return updateState(cache[url]);

      setIsLoading(true);
      const controller = new AbortController();
      let requestTimer = setTimeout(() => {
        console.log('La solicitud está tardando demasiado.');
      
        controller.abort(); // Cancel request
        clearData();
        alert('The report is being generated. You will receive an email when it is ready. Please do not close the application and try again in a few minutes.');
      }, 60000); // 60000 milliseconds = 1 minute
      api
        .get(url, { signal: controller.signal })
        .then((response) => {
          // Cancel timer when response is received
          clearTimeout(requestTimer);

          const { data } = response;
          if(data.success){
            console.log("data de moodle-login-report", data);
            if(data.logins && data.logins.length === 0){
              setChartData([]);
              alert("No logins data found.")
            }
            else {
              cache[url] = data.logins;
              updateState(data.logins);
            }
            setIsLoading(false);
          }
        })
        .catch((err) => {
          // Cancel timer when occurs an error
          clearTimeout(requestTimer);

          let message;
          if (err instanceof AxiosError && err.response) {

            let data = err.response.data;
            message = data.message;
          } else message = err.message;

          console.log('err: ', err)
          console.log(">>> [message]", message);
          
          if (err.name !== 'CanceledError') { // If the request is canceled, don't show the alert and dont clean the chart
            clearData();
            alert(`Could not obtain login data. ${message}`);
          }
          setIsLoading(false);
        });

      console.log('cache: ', cache)
      return () => {
        controller.abort();
      };
    }

  }, [instanceSelected, periodSelected, month, year, region]);

  useEffect(() => {

    console.log("useEffect loading storage: ", instanceSelected?.name, periodSelected, month, year);
    const updateStorageState = (data: any) => {
      setStorageChartData(data);
    };
    const clearData = () => {
      setStorageChartData([]);
      //setPeriodSelected('');
      // Set option TIMEFRAME to select period
      const selectTimeframe = document.getElementById('select-timeframe') as HTMLSelectElement;
      selectTimeframe.selectedIndex = 0;
    };

    if(instanceSelected && periodSelected ){

      const storageUrl=`/instances/${instanceSelected?.id}/${instanceSelected?.domain}/storage-report?ip=${instanceSelected?.ip}&name=${instanceSelected?.name}&lms=${instanceSelected?.lms}&deployment=${instanceSelected?.deployment}&period=${periodSelected}&month=${month}&year=${year}`;
      if (cache[storageUrl]) return updateStorageState(cache[storageUrl]);

      setIsStorageLoading(true);
      const controller = new AbortController();
      let requestTimer = setTimeout(() => {
        console.log('La solicitud está tardando demasiado.');
      
        controller.abort(); // Cancel request
        clearData();
        alert('The report is being generated. You will receive an email when it is ready. Please do not close the application and try again in a few minutes.');
      }, 60000); // 60000 milliseconds = 1 minute */
      api
        .get(storageUrl, { signal: controller.signal })
        .then((response) => {
          // Cancel timer when response is received
          clearTimeout(requestTimer);

          const { data } = response;
          if(data.success){
            console.log("data de storage-report", data);
            if(data.storageHistory && data.storageHistory.length === 0){
              setStorageChartData([]);
              alert("No storage history found");
            }
            else {
              const dataInMB = convertStorageToMB(data.storageHistory);
              cache[storageUrl] = dataInMB;
              updateStorageState(dataInMB);
            }
          }           
          setIsStorageLoading(false);
        })
        .catch((err) => {
          // Cancel timer when occurs an error
          clearTimeout(requestTimer);

          let message;
          if (err instanceof AxiosError && err.response) {

            let data = err.response.data;
            message = data.message;
          } else message = err.message;

          console.log('err: ', err)
          console.log(">>> [message]", message);
          
          if (err.name !== 'CanceledError') { // If the request is canceled, don't show the alert and dont clean the chart
            clearData();
            alert(`Could not obtain storage use data. ${message}`);
          }
          setIsStorageLoading(false);
        });

      console.log('cache: ', cache)
      return () => {
        controller.abort();
      };
    }

  }, [instanceSelected, periodSelected, month, year, region]);

  return (
    <>
      <div
        className={
          isLoading || isStorageLoading || navigation.state === "loading" || navigation.state === "submitting"
            ? "loading"
            : ""
        }
      ></div>
      <nav id="main-navbar" className="navbar navbar-expand-md">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="/images/logo-black.png" alt="Logo" height="64" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="d-flex ms-auto">
              <button
                className="btn btn-light me-3 back-button"
                title="Instances"
                onClick={() => {
                  navigate("/home");
                }}
              ></button>
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
            </div>
          </div>
        </div>
      </nav>
      <main className="container-fluid px-0">
        <div className="div-green">
          <div className="white-line"></div>
        </div>
        {isError && errorMessage ? (
          <div className="errorMessage">
            <p>{errorMessage}</p>
          </div>
        ) : (
            <>
              <div className="report-fields">
                <div className="row mb-2">
                  <div className="col-6 col-sm-4 col-lg-2 me-5 mb-4">
                    <select
                      className="form-select form-select-sm me-3 report-select"
                      aria-label=".form-select-lg"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        handleInstanceChange(e);
                      }}
                    >
                      <option value="">INSTANCE</option>
                      {instanceDataList &&
                        instanceDataList.length > 0 &&
                        instanceDataList.map((data: InstanceDataType, index) =>
                          <option key={index} data-object={JSON.stringify(data)}>
                            {data.name}
                          </option>
                        )}
                    </select>
                  </div>
                  {instanceSelected &&
                    <div className="col-6 col-sm-4 col-lg-2 mb-4">
                      <select
                        id="select-timeframe"
                        className="form-select form-select-sm me-3 report-select"
                        aria-label=".form-select-lg"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          handlePeriodChange(e.target.value);
                        }}
                        // defaultValue={periodSelected}
                        value={periodSelected}
                        disabled={isLoading} 
                      >
                        <option value="">TIMEFRAME</option>
                        <option key="1month" value="1month" > MONTHLY </option>
                        <option key="6months" value="6months" > 6 MONTHS </option>
                        <option key="global" value="global" > GLOBAL </option>
                      </select>
                    </div>
                  }
                </div>
              </div>
              <div className="row report-charts">
                {instanceSelected && storageChartData && storageChartData.length > 0 && periodSelected && !isStorageLoading && (
                  <div className="col-12 col-lg-4">
                    {loadStorageChartComponent()}
                  </div>
                )}
                {instanceSelected && chartData && chartData.length > 0 && periodSelected && !isLoading && (
                  <div className="col-12 col-lg-4">
                    {loadLoginsChartComponent()}
                  </div>
                )}
              </div>
            </>
        )}
      </main>
    </>
  );
}

export default Report;
