import { useEffect, useState } from 'react';
import { api } from "../../api";
import { AxiosError } from "axios";
import { InstanceDynamoType } from './types';
import InstanceDynamoRow from './InstanceDynamoRow';
import { useAuth, ROLE } from "../../auth/AuthContext";
import { Outlet } from 'react-router-dom';

function InstancesDynamoTable(){
    const { canUser } = useAuth();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [instanceList, setInstanceList] = useState<InstanceDynamoType[]>([]);

    useEffect(() => {
        if (isCreating) {
            //Getting instances dynamoDB report from the API
            setIsLoading(true);
            api
            .get(`/dynamo-instances`)
            .then(({ data }) => {
                console.log("data recibida en useEffect: ", data);
                if (data.success) {
                    setInstanceList(data.instances);
                    setIsLoading(false); //hide loading element
                }
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                const result = err.response.data as any;
                    setError(true);
                    setErrorMessage(result && result.message ? result.message : err.message);
                    setIsLoading(false); //hide loading element
                } else {
                    setError(true);
                    setErrorMessage("An error occurred while trying to get the instances dynamoDB report.");
                    setIsLoading(false); //hide loading element
                }
            });

            setIsCreating(false);
        }
    }, [isCreating]);

    if (error && errorMessage)
    return (
      <div className="errorMessage">
        <p>{errorMessage}</p>
      </div>
    );
  else
    return (
      <div className="row">
        <div className={isLoading ? "loading" : ""}></div>
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-hover" id="main-table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Instance ID</th>
                  <th scope="col">IP</th>
                  {canUser([ROLE.SUPERADMIN]) ? <th scope="col">Actions</th> : null}
                </tr>
              </thead>
              <tbody>
                {instanceList &&
                  instanceList.length > 0 &&
                  instanceList.map((instance: InstanceDynamoType) => (
                    <InstanceDynamoRow
                      key={instance.id}
                      instanceDynamo={instance}
                      setIsLoading={setIsLoading}
                      setIsCreating={setIsCreating}
                    />
                  ))} 
              </tbody>
            </table>
          </div>
        </div>
        {<Outlet />}
      </div>
    );
};

export default InstancesDynamoTable;
