import { ActionFunctionArgs } from "react-router-dom";

const loader = async ({ params }: ActionFunctionArgs) => {
  const region = window.localStorage.getItem("moodle-console-region");
  const uniqueId = params.uniqueId;

  return { region: region, uniqueId: uniqueId };
};

export default loader;
