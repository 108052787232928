import { FC, useEffect, useState } from 'react';
import { InstanceDynamoType } from './types';
import { useAuth, ROLE } from "../../auth/AuthContext";
import { api } from "../../api";
import { AxiosError } from "axios";
import { Link } from 'react-router-dom';

interface InstanceDynamoRowProps {
    instanceDynamo: InstanceDynamoType;
    setIsLoading: (isLoading: boolean) => void;
    setIsCreating: (isCreating: boolean) => void;
}
interface InstanceDynamoData {
  canDelete: boolean;
}
let dynamoInstanceCache: any = {};

const InstanceDynamoRow: FC<InstanceDynamoRowProps> = ({instanceDynamo, setIsLoading, setIsCreating}) => {
    const { canUser } = useAuth();
    const [canDelete, setCanDelete] = useState<boolean>(false);

    useEffect(() => {
      const url = `/dynamo-instances/${instanceDynamo.id}/data`;
      const updateState = (data: InstanceDynamoData) => {
        setCanDelete(data.canDelete);
      };
  
      //if (dynamoInstanceCache[url]) return updateState(dynamoInstanceCache[url]);
  
      const controller = new AbortController();
      api
        .get<InstanceDynamoData>(url, { signal: controller.signal })
        .then(({ data }) => {
          //console.log("data de ", instanceDynamo.name, data);
          dynamoInstanceCache[url] = data;
          updateState(data);
        })
        .catch(() => {});
  
      return () => {
        controller.abort();
      };
    }, [instanceDynamo]);

    const deleteInstanceFromDynamoDB = async (instanceDynamoId: string) => {
      if (instanceDynamoId) {
        try {
          setIsLoading(true);
          // Call endpoint
          const { data } = await api.delete(`/dynamo-instances/${instanceDynamoId}`);
          //console.log("response deleteInstanceFromDynamoDB: ", data);
  
          setIsLoading(false);
          if (data.success) {
            alert(`The operation was executed successfully. The table will be updated from DynamoDB.`);
            setIsCreating(true);
          }
        } catch (error: any) {
          setIsLoading(false);
  
          if (error instanceof AxiosError && error.response) {
            const response = error.response;
            const data = response.data;
            alert(data.message);
          } else alert(error.message);
        }
      }
    };

    return (
        <>
          <tr>
            <td> {instanceDynamo.name} </td>
            <td>{instanceDynamo.id}</td>
            <td>{instanceDynamo.ip}</td>
            {canUser([ROLE.SUPERADMIN]) ? 
            (<td>
              {instanceDynamo.shared &&
                (<Link
                  title="Domains"
                  className="options-table-instances information-icon me-2"
                  to={`information/${instanceDynamo.id}/${instanceDynamo.name}`}
                  style={{ fontSize: "84%" }}
                ></Link>)
              }
              {canDelete ? (
                <a
                  href="/#"
                  title='Delete instance from DynamoDB'
                  onClick={(e) => {
                    e.preventDefault();
                    if (window.confirm(`Are you sure you want to delete the instance ${instanceDynamo.name} from DynamoDB?`)) {
                      deleteInstanceFromDynamoDB(instanceDynamo.id);
                      //alert('Deleting ......')
                    }
                  }}
                >
                  <i className="fa-solid fa-trash-can text-danger fa-lg"></i>
                </a>
              ) : ''}
            </td>)
            : null}
          </tr>
        </>
      );
};

export default InstanceDynamoRow;
