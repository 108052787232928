import { useEffect } from "react";
import { Form, useActionData, useNavigation } from "react-router-dom";
import "../css/Login.css";

interface IActionLogin {
  username: string;
  password: string;
  twoFactor?: string;
  isEnabledTwoFactor?: boolean;
  fieldsErrors?: {
    username?: boolean;
    password?: boolean;
    twoFactor?: boolean;
  };
  error: boolean;
  messagge?: string;
}

function Login() {
  const data = useActionData() as IActionLogin | undefined;
  //console.log("response del actionData: ", data);

  const navigation = useNavigation();

  useEffect(() => {
    console.log("ejecutando effect");
    console.log('instance-request: ', window.localStorage.getItem("moodle-console-instance-request"));
    document.body.classList.add("login");

    return () => {
      document.body.classList.remove("login");
    };
  }, []);

  return (
    <main className="form-signin w-100 m-auto">
      <div
        className={navigation.state === "submitting" ? "loading auth" : ""}
      ></div>
      <Form method="post">
        <img className="mb-5 w-100 px-3" src="./images/logo-white.png" alt="" />

        <div className="row">
          <label className="col-sm-3 col-form-label" htmlFor="username">
            Username
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              name="username"
              placeholder="Type your username"
              className={`${
                data?.fieldsErrors?.username
                  ? "form-control wrong"
                  : "form-control"
              }`}
            />
          </div>
        </div>

        <div className="row mt-2">
          <label className="col-sm-3 col-form-label" htmlFor="password">
            Password
          </label>
          <div className="col-sm-9">
            <input
              type="password"
              name="password"
              placeholder="Type your password"
              className={`${
                data?.fieldsErrors?.password
                  ? "form-control wrong"
                  : "form-control"
              }`}
            />
          </div>
        </div>

        {!data?.isEnabledTwoFactor ? (
          <div className="row mt-3">
            <div className="col-sm-12 text-end">
              <button className="btn btn-md btn-primary px-4" type="submit">
                Login
              </button>
            </div>
          </div>
        ) : null}

        {data?.isEnabledTwoFactor ? (
          <div className="row mt-2">
            <label className="col-sm-3 col-form-label" htmlFor="twoFactor">
              2FA
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                name="twoFactor"
                placeholder="Type your 2FA Code"
                className={`${
                  data?.fieldsErrors?.twoFactor
                    ? "form-control wrong"
                    : "form-control"
                }`}
              />
            </div>
            <div className="col-sm-12 mt-3 text-end">
              <button className="btn btn-md btn-primary px-4" type="submit">
                Verify
              </button>
            </div>
          </div>
        ) : null}
        {data?.messagge && (
          <div className="col-sm-12 mt-1 text-end">
            <p>{data?.messagge}</p>
          </div>
        )}
      </Form>
    </main>
  );
}

export default Login;
