import { ActionFunctionArgs } from "react-router-dom";
// import { redirect } from "react-router-dom";
// import { api } from "../../api";
// import { AxiosError } from "axios";

const loader = ({ params }: ActionFunctionArgs) => {
  console.log('-----------Loader de addInstanceStandaloneLoader.ts-----------');
  window.localStorage.setItem("moodle-console-instance-request",`${params.token}`);
  console.log('LocalStorage:', window.localStorage.getItem("moodle-console-instance-request"));
  return {
    token: params.token
  };

  // v2
/*   try {
    console.log('---------------entre al loader de addInstanceStandaloneLoader.ts-----------------')
    console.log('params de addInstanceStandaloneLoader.ts: ', params);
    const data = await api.get("/user");
    console.log('-------------------datos de /user------------------------ ', data);
    // si devuelve estado 200 devolver token
    console.log('data de /user: ', data);
    if(data.status === 200) {
      return {
        token: params.token
      };
    } 
    if(data.status === 401) {
      window.localStorage.setItem(
        "moodle-console-instance-request",
        `${params.token}`
      );
      console.log(window.localStorage.getItem("moodle-console-instance-request"));
      console.log('///////////////error 401 - error 401-error 401 - error 401////////////////////');
      //return redirect("/");
      alert('error 401')
    }
    if(data.status === 403) {
      window.localStorage.setItem(
        "moodle-console-instance-request",
        ``
      );
      //return redirect("/");
      alert('error 403')
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log('///////////////AxiosError-AxiosError-AxiosError-AxiosError-AxiosError////////////////////');
      const response = error.response;
      const data = response.data;
      console.log(data.message);
      window.localStorage.setItem(
        "moodle-console-instance-request",
        `${params.token}`
      );
      console.log(window.localStorage.getItem("moodle-console-instance-request"));
      console.log('///////////////AxiosError-AxiosError-AxiosError-AxiosError-AxiosError////////////////////');
    } else 
      console.log('error del catch: ', error);
  } */

};

export default loader;
