import { ActionFunctionArgs } from "react-router-dom";

const loader = ({ params }: ActionFunctionArgs) => {
  return {
    instanceId: params.instanceId,
    instanceIp: params.instanceIp,
    volumeId: params.volumeId,
    volumeName: params.volumeName,
    volumeDomain: params.volumeDomain,
  };
};

export default loader;
