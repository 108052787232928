import { ActionFunctionArgs } from "react-router-dom";

const loader = ({ params }: ActionFunctionArgs) => {
  console.log("params.instanceId", params.instanceId);
  console.log("params.instanceName", params.instanceName);
  console.log("params.instanceDeployment", params.instanceDeployment);
  return {
    instanceId: params.instanceId,
    instanceName: params.instanceName,
    instanceDeployment: params.instanceDeployment,
  };
};

export default loader;
