import { FC } from "react";
import { useAuth, ROLE } from "../../auth/AuthContext";

interface PluginTableProps {
  plugins: string[] | undefined;
  instanceId: string;
  instanceIp: string;
  region: any;
  path: string;
  pluginDirectory: string;
  onDelete: (
    pluginName: string,
    instanceId: string,
    instanceIp: string,
    region: any,
    path: string,
    pluginDirectory: string
  ) => Promise<void>;
}

const PluginTable: FC<PluginTableProps> = ({
  plugins,
  onDelete,
  instanceId,
  instanceIp,
  region,
  path,
  pluginDirectory,
}) => {
  const { canUser } = useAuth();
  console.log("plugins desde la tabla", plugins);

  const DeletePlugin = (pluginName: string) => {
    if (!pluginName) {
      return;
    }
    console.log("name: ", pluginName);

    onDelete(pluginName, instanceId, instanceIp, region, path, pluginDirectory);
  };

  return (
    <>
      {plugins && plugins.length > 0 && (
        <table
          id="plugins-table"
          className=" table table-bordered table-hover table-sm align-middle w-100 text-wrap"
        >
          <thead className="table-success">
            <tr>
              <th scope="col">Name</th>
              {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
                <th scope="col"></th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {plugins?.map((plugin: string) => (
              <tr key={plugin}>
                <td>{plugin}</td>
                {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
                  <td>
                    <a
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm(
                            "Please confirm you want to delete the plugin?"
                          )
                        ) {
                          DeletePlugin(plugin);
                        }
                      }}
                    >
                      <i className="fa-solid fa-trash-can text-danger"></i>
                    </a>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default PluginTable;
