import { ActionFunctionArgs, redirect } from "react-router-dom";
import { api } from "../../api";

const loader = async ({ params }: ActionFunctionArgs) => {
  const region = window.localStorage.getItem("moodle-console-region");
  const resourcesToken = params.token;
  const action = params.action;
  console.log(resourcesToken, action, region);

  try {
    const response = await api.get(
      `/instances/delete-details?token=${resourcesToken}&action=${action}&region=${region}`
    );
    const data = response.data;
    console.log("response de delete-details: ", data);

    let deleteLMS = false;
    if (action === "delete-lms") deleteLMS = true;
    return {
      deleteLMS: deleteLMS,
      resources: data.resources,
      region: region,
      resourcesToken: resourcesToken,
      deleteDomain: data.deleteDomain
    };
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        window.localStorage.setItem(
          "moodle-console-urlDelete",
          `home/delete/${resourcesToken}/${action}`
        );
        return redirect("/");
      } else
        return {
          error: true,
          resources: {},
          messagge: error.response.data.message,
        };
    }
  }
};

export default loader;
