import { ActionFunctionArgs } from "react-router-dom";

const loader = ({ params }: ActionFunctionArgs) => {
  // console.log("params.instanceId", params.instanceId);
  // console.log("params.instanceName", params.instanceName);
  // console.log("params.instanceIp", params.instanceIp);
  return {
    instanceId: params.instanceId,
    instanceName: params.instanceName,
    instanceIp: params.instanceIp,
  };
};

export default loader;
