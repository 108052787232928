import axios, { AxiosError } from "axios";

interface Tokens {
  accessToken: string;
  refreshToken: string;
}

let accessToken = "";
let refreshingInProgress = false;
let queue: any = [];

function onCleanQueue() {
  queue.map((cb: any) => cb());
}

export const api = axios.create({
  baseURL: `/api/v1`, // ${process.env.REACT_APP_CONSOLE_API}
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      if (typeof config.headers["Content-Type"] === "undefined")
        config.headers["Content-Type"] = "application/json";
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (res) => {
    if (res.data.accessToken && typeof res.data.accessToken === "string") {
      accessToken = res.data.accessToken;
    }

    return res;
  },
  (error) => {
    const originalConfig = error.config;

    if (error.response && error.response.status === 401) {
      if (!refreshingInProgress) {
        refreshingInProgress = true;

        api
          .post<Tokens>("/refresh-token")
          .then((response) => {
            if (response.data.accessToken)
              accessToken = response.data.accessToken;

            refreshingInProgress = false;
            onCleanQueue();
            queue = [];
          })
          .catch((error) => {
            refreshingInProgress = false;
            if (error instanceof AxiosError) {
              if (error.response?.status === 403) {
                api
                  .post("/logout")
                  .then(() => {})
                  .catch(() => {});
                window.location.href = window.location.origin;
              }
            }
          });
      }

      return new Promise((resolve) => {
        queue.push(() => {
          const token = `Bearer ${accessToken}`;
          api.defaults.headers.common["Authorization"] = token;

          resolve(api(originalConfig));
        });
      });
    }

    if (error.response && error.response.status === 403) {
      api
        .post("/logout")
        .then(() => {})
        .catch(() => {});
      window.location.href = window.location.origin;
    }

    return Promise.reject(error);
  }
);
