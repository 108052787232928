import { FC } from "react";

interface DatabasesTableProps {
    databases: string[];
  }

const DatabasesTable: FC<DatabasesTableProps> = ({ databases }) => {

    return (
        <>
        { databases && databases.length > 0 &&
        <div className="row">
            <div className="col-12 text-center scrollit-dblist">
                <table id="db-table" className=" table table-bordered table-hover table-sm align-middle w-100 text-wrap" >
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {databases?.map((db: string) => (
                            <tr key={ db }>
                                <td>{ db }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        }
        </>
    );
}

export default DatabasesTable;