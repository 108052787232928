import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import DomainTable from "../Domain/DomainTable";
import { api } from "../../../api";
import { AxiosError } from "axios";

interface ILoaderInstanceDynamoInformationModal {
    id: string;
    name: string;
}

const InstanceDynamoInformationModal = () => {
    const loaderData = useLoaderData() as ILoaderInstanceDynamoInformationModal;
    const dynamoInstanceId = loaderData.id;
    const dynamoInstanceName = loaderData.name;
    console.log(dynamoInstanceId);

    const navigate = useNavigate();

    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [domainList, setDomainList] = useState<string[]>([]);
    const [isCreating, setIsCreating] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isCreating) {
          setIsLoading(true); //show loading element
    
          // Getting domains of dynamo instance
          api
            .get(`/dynamo-instances/${dynamoInstanceId}/domains`)
            .then(({ data }) => {
              //console.log("data recibida en useEffect: ", data);
              if (data.success) {
                setDomainList(data.domains);
              }
              setIsLoading(false); //hide loading element
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                const result = err.response.data as any;
                    setError(true);
                    setErrorMessage(result && result.message ? result.message : err.message);
                    setIsLoading(false); //hide loading element
                } else {
                    setError(true);
                    setErrorMessage("An error occurred while trying to get the instances dynamoDB report.");
                    setIsLoading(false); //hide loading element
                }
            });
    
          setIsCreating(false);
        }
      }, [isCreating, dynamoInstanceId]);

    const deleteDomainFromInstanceDynamo = async (instanceDynamoId: string, domain: string) => {
        if (instanceDynamoId && domain) {
          try {
            setIsLoading(true);
            // Call endpoint
            const { data } = await api.delete(`/dynamo-instances/${instanceDynamoId}/domains/${domain.replace(/^https?:\/\//, '')}`);
            console.log("response deleteDomainFromInstanceDynamo: ", data);
    
            setIsLoading(false);
            if (data.success) {
              alert(`The operation was executed successfully. The table will be updated from DynamoDB.`);
              setIsCreating(true);
            }
          } catch (error: any) {
            setIsLoading(false);
    
            if (error instanceof AxiosError && error.response) {
              const response = error.response;
              const data = response.data;
              alert(data.message);
            } else alert(error.message);
          }
        }
    };

    return (
        <>
            <div className="modal-overlay">
                <div className={isLoading /* || navigation.state === "submitting" */ ? "loading" : ""} ></div> 
                <div className="modal-dynamo">
                <h4 className="title-modal-actions">Information</h4>
                <button
                    className="close-modal-button"
                    onClick={() => navigate('/reports/dymano-instances') }
                >
                    x{" "}
                </button>
                <div className="modal-actions-body">
                    <div className="section-modal-action">
                        <div className="section-title">
                            <div className="col-sm-12">
                            <h5>{dynamoInstanceName}</h5>
                            </div>
                        </div>
                        <div className="section-content domains">
                            { error && errorMessage ? (
                                <div className="pathMessage">
                                    <p>{errorMessage}</p>
                                </div>
                            ) : (
                                <DomainTable
                                    dynamoInstanceId={dynamoInstanceId}
                                    domains={domainList}
                                    onDelete={deleteDomainFromInstanceDynamo}
                                />
                            )}
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
};

export default InstanceDynamoInformationModal;