import React from 'react';
import { useAuth, ROLE } from '../../../auth/AuthContext';
import DomainRow from './DomainRow';

interface DomainTableProps {
    dynamoInstanceId: string;
    domains: string[];
    onDelete: (dynamoInstanceId: string, domain: string) => void;
}

const DomainTable: React.FC<DomainTableProps> = ({dynamoInstanceId, domains, onDelete}) => {
    const { canUser } = useAuth();

    return (
        <>
          {domains && domains.length > 0 && (
            <div className="table-responsive">
              <table
                id="volumes-table"
                className=" table table-bordered table-hover table-sm align-middle w-100 text-wrap"
              >
                <thead>
                  <tr>
                    <th scope="col">URL</th>
                    {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
                      <th scope="col">ACTIONS</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {domains?.map((domain: string) => (
                    <DomainRow
                        key={domain}
                        dynamoInstanceId={dynamoInstanceId}
                        url={domain}
                        onDelete={onDelete}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      );
};

export default DomainTable;