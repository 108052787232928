import { ActionFunctionArgs, redirect } from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";

const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const instanceName = formData.get("instanceName");
  console.log("instanceName: ", instanceName);


  try {
    const response = await api.post(`/shared-instance`, {
      sharedServer: true,
      instanceName: instanceName,
    });

    const data = response.data;

    if (data.success) {
      console.log("response ok: ", data);
      const deployId = data.deployId;
      return { error: false, startCreationProcess: true, deployId: deployId };
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const response = error.response;
      const { data } = response;

      //not authorized
      if (response.status === 401) return redirect("/");

      //incorrect parameters
      if (response.status === 400 && data.fieldsError) {
        return {
          error: true,
          fieldsErrors: data.fields,
          startCreationProcess: false,
          deployId: "",
        };
      }

      //another error
      alert(data.message);
      return {
        error: true,
        message: data.message,
        startCreationProcess: false,
        deployId: "",
      };
    }
  }
};

export default action;
