import { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import DragDropFile from "./DragDropFile";
import PluginTable from "./PluginTable";
import { api } from "../../api";
import { AxiosError } from "axios";
import { useAuth, ROLE } from "../../auth/AuthContext";

type FileType = {
  name: string;
  size: string;
  modifyTime: string;
  path: string;
};

interface ILoaderUploadModal {
  instanceId: string;
  instanceIp: string;
  instanceLMS: string;
}

const InstanceUploadModal = () => {
  const { canUser } = useAuth();
  const data = useLoaderData() as ILoaderUploadModal;
  const instanceId = data.instanceId;
  const instanceIp = data.instanceIp;
  const instanceLMS = data.instanceLMS;
  console.log("data del loader: ", data);
  const [pathList, setPathList] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDragDropDisabled, setIsDragDropDisabled] = useState<boolean>(false);
  const [isPlugin, setIsPlugin] = useState<boolean>(false);

  const [path, setPath] = useState<string>("");
  const [pluginDirectory, setPluginDirectory] = useState<string>("");

  const [files, setFiles] = useState<FileType[]>();
  const [showFiles, setShowFiles] = useState<boolean>(false);

  const [plugins, setPlugins] = useState<string[]>();
  const [showPlugins, setShowPlugins] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loadError, setLoadError] = useState<boolean>(false);
  const [loadErrorMessage, setLoadErrorMessage] = useState("");

  const selectPathRef = useRef<HTMLSelectElement>(null);
  const selectPluginDirectoryRef = useRef<HTMLSelectElement>(null);

  const navigate = useNavigate();
  const { region } = useOutletContext<{region: string}>();
  console.log('region de upload: ', region);

  const [isCreating, setIsCreating] = useState<boolean>(true);

  function clean() {
    setShowFiles(false);
    setShowPlugins(false);
    setError(false);
    setPath("");
    if (selectPluginDirectoryRef.current) {
      selectPluginDirectoryRef.current.classList.remove("select-empty");
    }
  }

  function handleChange(value: string) {
    console.log(value);
    clean();
    setPluginDirectory("");
    if (selectPathRef.current) selectPathRef.current.value = "";

    if (value === "File") {
      //add the /img_clientes/ part to each path
      //let fixedPaths = pathList?.map((l) => l + "/img_clientes/");
      let fixedPaths = pathList?.map(function (l: string) {
        if (l.endsWith("img_clientes/")) return l;
        else return l + "/img_clientes/";
      });
      console.log(fixedPaths);
      //update paths list
      setPathList(fixedPaths);
      setIsPlugin(false);
      return;
    }
    if (value === "Plugin") {
      // remove the img_clientes/ part to each path
      let fixedPaths = pathList?.map((l) => l.replace("/img_clientes/", ""));
      console.log(fixedPaths);
      //update paths list
      setPathList(fixedPaths);
      setIsPlugin(true);
      return;
    }
  }

  function handleChangePluginFolder(value: string) {
    console.log(value);
    clean();
    if (selectPathRef.current) selectPathRef.current.value = "";

    if (!value || (value && value.toString().trim() === "")) {
      setPluginDirectory("");
      return;
    }

    setPluginDirectory(value);
  }

  const handleChangePath = async (value: string) => {
    console.log(instanceId, instanceIp, region, value);
    clean();

    if (!value || (value && value.toString().trim() === "")) {
      setPath("");
      setIsDragDropDisabled(true);
      return;
    }

    if (isPlugin && (!pluginDirectory || !pluginDirectory.length)) {
      if (selectPluginDirectoryRef.current) {
        console.log("no hay directorio d plugin");
        selectPluginDirectoryRef.current.classList.add("select-empty");
      }
      return;
    }

    //otherwise load files/plugins from path
    setIsLoading(true); // show loading element
    setPath(value);
    setIsDragDropDisabled(true); // disable component drag&drop

    if (isPlugin) {
      let fullPath = value;
      console.log("fullPath: ", fullPath);
      if (!value.endsWith("/")) fullPath = `${fullPath}/`;
      fullPath = `${fullPath}${pluginDirectory}`;
      console.log("fullPath: ", fullPath);

      try {
        const { data } = await getPluginsList(fullPath);

        console.log("response de plugins-list: ", data);

        if (data.success && data.plugins.length > 0) {
          setError(false);

          setPlugins(data.plugins);
          setShowPlugins(true);
          setIsLoading(false);
        } else {
          setError(true);
          setErrorMessage(data.message);
          setIsLoading(false);
        }
      } catch (error: any) {
        var message;
        if (error instanceof AxiosError && error.response) {
          message = error.response.data.message;
        } else message = error.message;
        setError(true);
        setErrorMessage(message);
        setIsLoading(false);
      }
    } else {
      try {
        const { data } = await getFilesList(value);

        console.log("response de file-list: ", data);

        if (data.success) {
          if (data.files.length > 0) {
            setError(false);

            setFiles(data.files);
            setShowFiles(true);
            setIsLoading(false);
          } else {
            setShowFiles(false);

            setError(true);
            setErrorMessage("There are no files available!");
            setIsLoading(false);
          }
        } else {
          setError(true);
          setErrorMessage(data.message);
          setIsLoading(false);
        }
      } catch (error: any) {
        var message_error;
        if (error instanceof AxiosError && error.response) {
          message_error = error.response.data.message;
        } else message_error = error.message;
        setError(true);
        setErrorMessage(message_error);
        setIsLoading(false);
      }
    }

    //finish
    setIsDragDropDisabled(false); // enable component drag&drop
  };

  const deleteFile = async (fileName: string) => {
    console.log(instanceId, instanceIp, region, path, fileName);

    setIsLoading(true); // show loading element
    setIsDragDropDisabled(true); //disable component drag&drop

    try {
      const response = await api.delete(
        `/file/${instanceId}/${fileName}?ip=${instanceIp}&path=${path}&region=${region}`
      );
      const data = response.data;
      console.log("response de delete-file: ", data);

      //finish delete
      setIsLoading(false); //hide loading element
      setIsDragDropDisabled(false); //enable component drag&drop

      if (data.success) {
        console.log(data);
        //delete file from state
        setFiles(files?.filter((f) => f.name !== fileName));
      }
    } catch (error: any) {
      if (error instanceof AxiosError && error.response) {
        alert(error.response.data.message);
      } else alert(error.message);
    }
  };

  const uploadFile = async (
    instanceId: string,
    instanceIp: string,
    region: any,
    path: string,
    file: any
  ): Promise<void> => {
    // Begin the upload operation
    console.log("Begin the upload file operation");

    setIsLoading(true); //show loading element
    setIsDragDropDisabled(true); //disable component drag&drop

    console.log("Parameters to Upload File");
    console.log(instanceId);
    console.log(instanceIp);
    console.log(region);
    console.log(path);
    console.log(file);

    const formData = new FormData();
    formData.append("ip", instanceIp);
    formData.append("path", path);
    formData.append("file", file);
    formData.append("action", ""); // not used yet
    formData.append("region", region);

    try {
      const { data } = await api.post(`/file/${instanceId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("response de upload-file: ", data);

      setIsLoading(false); //hide loading element
      if (data.success) {
        console.log(data);
        alert(
          "The file has been uploaded successfully. Please click on accept to update the file list."
        );
      }

      setIsDragDropDisabled(false); //enable component drag&drop
    } catch (error: any) {
      setIsLoading(false);
      setIsDragDropDisabled(false);

      if (error instanceof AxiosError && error.response) {
        alert(error.response.data.message);
      } else alert(error.message);
    }

    try {
      //then reload files from instance
      setIsLoading(true); //show loading element
      setIsDragDropDisabled(true); //disable component drag&drop
      const responseFilesList = await getFilesList(path);
      const result = responseFilesList.data;
      console.log("file list updated: ", result);

      if (result.success) {
        setError(false);

        setFiles(result.files);
        setShowFiles(true);
        setIsLoading(false); //hide loading element
        setIsDragDropDisabled(false); //enable component drag&drop
      }
    } catch (error: any) {
      setShowFiles(false);
      setError(true);
      setIsLoading(false); //hide loading element
      setIsDragDropDisabled(false); //enable component drag&drop

      if (error instanceof AxiosError && error.response) {
        setErrorMessage(error.response.data.message);
      } else setErrorMessage(error.message);
    }
  };

  const getFilesList = (path: string) => {
    return api.get(
      `/file/${instanceId}?ip=${instanceIp}&path=${path}&region=${region}`
    );
  };

  const getPluginsList = (path: string) => {
    return api.get(
      `/plugin/${instanceId}?ip=${instanceIp}&path=${path}&region=${region}`
    );
  };

  const deletePlugin = async (
    pluginName: string,
    instanceId: string,
    instanceIp: string,
    region: any,
    path: string,
    pluginDirectory: string
  ) => {
    console.log("voy a borrar");
    console.log("instanceId: ", instanceId);
    console.log("instanceIp: ", instanceIp);
    console.log("path: ", path);
    console.log("pluginDirectory: ", pluginDirectory);
    console.log("pluginName: ", pluginName);

    setIsLoading(true); // show loading element
    setIsDragDropDisabled(true); //disable component drag&drop

    try {
      //validate path
      let fullPath = path;
      if (!path.endsWith("/")) fullPath = `${path}/`;
      fullPath = `${fullPath}${pluginDirectory}`;
      console.log("fullPath: ", fullPath);

      const { data } = await api.delete(
        `/plugin/${instanceId}/${pluginName}?ip=${instanceIp}&path=${fullPath}&region=${region}`
      );

      console.log("response de delete-file: ", data);

      //finish delete
      setIsLoading(false); //hide loading element
      setIsDragDropDisabled(false); //enable component drag&drop

      if (data.success) {
        console.log(data);
        //delete file from state
        setPlugins(plugins?.filter((p) => p !== pluginName));
      }
    } catch (error: any) {
      setIsLoading(false); //hide loading element
      setIsDragDropDisabled(false); //enable component drag&drop

      if (error instanceof AxiosError && error.response) {
        alert(error.response.data.message);
      } else alert(error.message);
    }
  };

  const uploadPlugin = async (
    instanceId: string,
    instanceIp: string,
    region: any,
    path: string,
    file: any
  ): Promise<void> => {
    // Begin the upload operation
    console.log("Begin the upload plugin operation");

    setIsLoading(true); //show loading element
    setIsDragDropDisabled(true); //disable component drag&drop

    //validate path
    let fullPath = path;
    if (!path.endsWith("/")) fullPath = `${path}/`;
    fullPath = `${fullPath}${pluginDirectory}`;
    console.log("fullPath: ", fullPath);

    console.log("Parameters to Upload Plugin");
    console.log(instanceId);
    console.log(instanceIp);
    console.log(region);
    console.log(fullPath);
    console.log(file);

    try {
      const formData = new FormData();
      formData.append("ip", instanceIp);
      formData.append("path", fullPath);
      formData.append("file", file);
      formData.append("region", region);

      const { data } = await api.post(`/plugin/${instanceId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("response de upload-plugin: ", data);

      setIsLoading(false); //hide loading element
      if (data.success) {
        console.log(data);
        alert(
          "The plugin has been uploaded successfully. Please click on accept to update the plugin list."
        );
      }
      setIsDragDropDisabled(false); //enable component drag&drop
    } catch (error: any) {
      setIsLoading(false); //hide loading element
      setIsDragDropDisabled(false); //enable component drag&drop

      if (error instanceof AxiosError && error.response) {
        alert(error.response.data.message);
      } else alert(error.message);
    }

    try {
      //then reload plugins from instance
      setIsLoading(true); //show loading element
      setIsDragDropDisabled(true); //disable component drag&drop
      const responsePluginsList = await getPluginsList(fullPath);
      const result = responsePluginsList.data;
      console.log("plugins list updated: ", result);

      if (result.success) {
        setError(false);

        setPlugins(result.plugins);
        setShowPlugins(true);
        setIsLoading(false); //hide loading element
        setIsDragDropDisabled(false); //enable component drag&drop
      }
    } catch (error: any) {
      setShowPlugins(false);
      setError(true);
      setIsLoading(false); //hide loading element
      setIsDragDropDisabled(false); //enable component drag&drop

      if (error instanceof AxiosError && error.response) {
        setErrorMessage(error.response.data.message);
      } else setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    console.log("useEffect de InstanceInformationModal: ", region);

    if (isCreating) {
      setIsLoading(true); //show loading element
      console.log("isLoading: ", isLoading);

      //Obteniendo instance app-directories
      api
        .get(`/app-directory/${instanceId}?ip=${instanceIp}&region=${region}`)
        .then(({ data }) => {
          console.log("data recibida de paths en useEffect: ", data);
          if (data.success) {
            setPathList(data.paths);
            setIsLoading(false); //hide loading element
          }
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            const result = err.response.data as any;
            setLoadError(true);
            setLoadErrorMessage(
              result && result.message ? result.message : err.message
            );
            setIsLoading(false); //hide loading element
          } else {
            setLoadError(true);
            setLoadErrorMessage(
              "An error has occurred. Failed to load instance app directories."
            );
            setIsLoading(false); //hide loading element
          }
        });

      setIsCreating(false);
    }
  }, [isCreating, instanceId, instanceIp, isLoading, region]);

  return (
    <>
      <div className="modal-overlay">
        <div className={isLoading ? "loading" : ""}></div>
        <div className="modal-upload upload-file">
          <h4 className="title-modal-upload">Upload</h4>
          <button
            className="close-modal-button"
            onClick={() => /* navigate(-1) */ navigate("/home/instances")}
          >
            x{" "}
          </button>
          <div className="modal-actions-body">
            <div className="section-modal upload-file">
              <div className="section-content upload-file">
                {loadError && loadErrorMessage ? (
                  <div className="col-12 mt-3">
                    <div className="alert alert-info" role="alert">
                      <span>{loadErrorMessage}</span>
                    </div>
                  </div>
                ) : (
                  <div id="upload-file-section" className="row">
                    <div className="col-6">
                      <select
                        defaultValue="File"
                        className="form-select form-select-sm"
                        aria-label=".form-select-lg"
                        onChange={(e) => {
                          handleChange(e.target.value);
                        }}
                      >
                        <option value="File">FILE</option>
                        {instanceLMS === "Totara" && (
                          <option value="Plugin">PLUGIN</option>
                        )}
                      </select>
                    </div>
                    {isPlugin && (
                      <div className="col-6">
                        <select
                          ref={selectPluginDirectoryRef}
                          className="form-select form-select-sm"
                          aria-label=".form-select-lg"
                          onChange={(e) => {
                            handleChangePluginFolder(e.target.value);
                          }}
                        >
                          <option value="">SELECT A PLUGIN DIRECTORY</option>
                          <option value="mod/">mod</option>
                          <option value="local/">local</option>
                          <option value="theme/">theme</option>
                          <option value="blocks/">blocks</option>
                        </select>
                      </div>
                    )}
                    <div className="col-12 mt-3">
                      <select
                        ref={selectPathRef}
                        name="paths-select"
                        className="form-select form-select-sm"
                        aria-label=".form-select-lg"
                        onChange={(e) => {
                          e.preventDefault();
                          handleChangePath(e.target.value);
                        }}
                      >
                        <option value="">SELECT A PATH</option>
                        {pathList?.map((path: string) => (
                          <option key={path} value={path}>
                            {path}
                          </option>
                        ))}
                      </select>
                    </div>
                    {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) && path && (
                      <div className="col-12 mt-3">
                        <DragDropFile
                          onUpload={isPlugin ? uploadPlugin : uploadFile}
                          instanceId={instanceId}
                          instanceIp={instanceIp}
                          region={region}
                          path={path}
                          disabled={isDragDropDisabled}
                          isPlugin={isPlugin}
                        />
                      </div>
                    )}
                  </div>
                )}
                {error && (
                  <div className="col-12 mt-3">
                    <div className="alert alert-info" role="alert">
                      <span>{errorMessage}</span>
                    </div>
                  </div>
                )}

                {showFiles && (
                  <div className="row">
                    <div className="col-12 mt-4 text-center scrollit">
                      {files && files.length > 0 && (
                        <table
                          id="files-table"
                          className=" table table-bordered table-hover table-sm align-middle w-100 text-wrap"
                        >
                          <thead className="table-success">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Size</th>
                              <th scope="col">Date</th>
                              {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
                                <th scope="col"></th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {files?.map((file: FileType) => (
                              <tr key={file.name}>
                                <td>{file.name}</td>
                                <td>{file.size}</td>
                                <td>{file.modifyTime}</td>
                                {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
                                  <td>
                                    <a
                                      href="/#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          !path ||
                                          (path &&
                                            path.toString().trim() === "")
                                        )
                                          return;

                                        if (
                                          window.confirm(
                                            "Please confirm you want to delete the file?"
                                          )
                                        ) {
                                          deleteFile(file.name);
                                        }
                                      }}
                                    >
                                      <i className="fa-solid fa-trash-can text-danger"></i>
                                    </a>
                                  </td>
                                ) : null}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                )}
                {showPlugins && (
                  <div className="row">
                    <div className="col-12 mt-4 text-center scrollit">
                      <PluginTable
                        onDelete={deletePlugin}
                        plugins={plugins}
                        instanceId={instanceId}
                        instanceIp={instanceIp}
                        region={region}
                        path={path}
                        pluginDirectory={pluginDirectory}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstanceUploadModal;
