import { useState } from "react";
import {
  useLoaderData,
  useNavigate,
  //useNavigation,
} from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";
import { useAuth, ROLE } from "../../auth/AuthContext";

interface ILoaderModal {
  instanceId: string;
  instanceIp: string;
  instanceName: string;
}

enum commandEnum {
  RESTART_PHP = "restart-php",
  STATUS_PHP = "status-php",
  RESTART_WEB_SERVICE = "restart-web-service",
  STATUS_WEB_SERVICE = "status-web-service",
  UPDATE_SYSTEM = "update-system",
  PHP_LOGS = "php-logs",
  WEB_SERVICE_LOGS = "web-service-logs",
  CLEAN_PHP_LOGS = "clean-php-logs",
  CLEAN_WEB_SERVICE_LOGS = "clean-web-service-logs"
}

const InstanceCommandsModal = () => {
  const loader = useLoaderData() as ILoaderModal;
  const { canUser } = useAuth();

  const instanceId = loader.instanceId;
  const instanceName = loader.instanceName;
  const instanceIp = loader.instanceIp;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showOutput, setShowOutput] = useState<boolean>(false);
  const [contentOuput, setContentOuput] = useState<string>("");

  const runCommandOnInstance = async (command: commandEnum) => {
    setShowOutput(false);
    console.log("instanceId: ", instanceId, command);
    const region = window.localStorage.getItem("moodle-console-region");

    try {
      setIsLoading(true);
      // Execute command on instance
      const { data } = await api.post(`/instances/${instanceId}/command`, {
        region: region,
        instanceIp: instanceIp, 
        command: command,
      });

      setIsLoading(false);
      if (data.success){
        // Just for PHP_LOGS and WEB_SERVICE_LOGS
        if(command === commandEnum.PHP_LOGS || command === commandEnum.WEB_SERVICE_LOGS){
          if(data.output && data.output !== ''){
            // Download file with logs
            downloadLog(data.output, command);
          }
          else {
            alert("The operation was executed successfully but there are no logs to show.");
          }
        }
        else {
          console.log("response: ", data);
          if(data.code === 0){
            alert("The operation was executed successfully.");
            if(data.output && data.output !== ''){
              setContentOuput(data.output);
              setShowOutput(true);
            }
          }
        }
      }
    } catch (error: any) {
      setIsLoading(false);

      if (error instanceof AxiosError && error.response) {
        const response = error.response;
        const data = response.data;
        alert(data.message);
      } else alert(error.message);
    }
  };

  function downloadLog(logContent: string, service: commandEnum) {
    // Create a Blob with the log content
    const blob = new Blob([logContent], { type: 'text/plain' });
  
    // Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', URL.createObjectURL(blob));
    const fileName = service === commandEnum.PHP_LOGS ? `${instanceName}-php-log.txt` : `${instanceName}-nginx-log.txt`;
    downloadLink.setAttribute('download', fileName); // Nombre del archivo a descargar

    document.body.appendChild(downloadLink);
    downloadLink.click();
    // Download is done, so we can remove the link
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadLink.href);
  }

  return (
    <>
      <div className="modal-overlay">
        <div
          className={
            isLoading /* || navigation.state === "submitting" */?  "loading" : ""
          }
        ></div>
        <div className="modal-commands">
          <h4 className="title-modal-actions">Commands</h4>
          <button
            className="close-modal-button"
            onClick={() => navigate("/home/instances")}
          >
            x{" "}
          </button>
          <div className="modal-actions-body">
            <div className="section-modal-command">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>Php Service</h5>
                </div>
              </div>
              <div className="section-content-command">
                <div className="row mb-4">
                  <div className="col-12 col-md-6">
                    <button
                      type="button"
                      className="btn btn-custom btn-main btn-modal-command"
                      onClick={() => {
                        console.log(commandEnum.STATUS_PHP);
                        runCommandOnInstance(commandEnum.STATUS_PHP);
                      }}
                    >
                      Check Status
                    </button>
                  </div>
                  { canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) && (
                    <div className="col-12 col-md-6">
                      <button
                        type="button"
                        className="btn btn-warning btn-main btn-modal-command"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to restart PHP service on instance ${instanceName}?`)){
                            console.log(commandEnum.RESTART_PHP)
                            runCommandOnInstance(commandEnum.RESTART_PHP);
                          }
                        }}
                      >
                        Restart
                      </button>
                    </div>
                  )}
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-md-6">
                    <button
                      type="button"
                      className="btn btn-custom btn-main btn-modal-command"
                      onClick={() => {
                        console.log(commandEnum.PHP_LOGS);
                        runCommandOnInstance(commandEnum.PHP_LOGS);
                      }}
                    >
                      Get Logs
                    </button>
                  </div>
                  { canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) && (
                    <div className="col-12 col-md-6">
                      <button
                        type="button"
                        className="btn btn-warning btn-main btn-modal-command"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to clean PHP logs on instance ${instanceName}?`)){
                            console.log(commandEnum.CLEAN_PHP_LOGS)
                            runCommandOnInstance(commandEnum.CLEAN_PHP_LOGS);
                          }
                        }}
                      >
                        Clean Logs
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr className="divisor-modal-command" />
            <div className="section-modal-command">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>Web Service</h5>
                </div>
              </div>
              <div className="section-content-command">
                <div className="row mb-4">
                  <div className="col-12 col-md-6">
                    <button
                      type="button"
                      className="btn btn-custom btn-main btn-modal-command"
                      onClick={() => {
                        console.log(commandEnum.STATUS_WEB_SERVICE)
                        runCommandOnInstance(commandEnum.STATUS_WEB_SERVICE);
                      }}
                    >
                      Check Status
                    </button>
                  </div>
                  { canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) && (
                    <div className="col-12 col-md-6">
                      <button
                        type="button"
                        className="btn btn-warning btn-main btn-modal-command"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to restart web service on instance ${instanceName}?`)){
                            console.log(commandEnum.RESTART_WEB_SERVICE)
                            runCommandOnInstance(commandEnum.RESTART_WEB_SERVICE);
                          }
                        }}
                      >
                        Restart
                      </button>
                    </div>
                  )}
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-md-6">
                    <button
                      type="button"
                      className="btn btn-custom btn-main btn-modal-command"
                      onClick={() => {
                        console.log(commandEnum.WEB_SERVICE_LOGS);
                        runCommandOnInstance(commandEnum.WEB_SERVICE_LOGS);
                      }}
                    >
                      Get Logs
                    </button>
                  </div>
                  { canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) && (
                    <div className="col-12 col-md-6">
                      <button
                        type="button"
                        className="btn btn-warning btn-main btn-modal-command"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to clean web service logs on instance ${instanceName}?`)){
                            console.log(commandEnum.CLEAN_WEB_SERVICE_LOGS)
                            runCommandOnInstance(commandEnum.CLEAN_WEB_SERVICE_LOGS);
                          }
                        }}
                      >
                        Clean Logs
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) && (
            <>
              <hr className="divisor-modal-command" />
              <div className="section-modal-command">
                <div className="section-title">
                  <div className="col-sm-12">
                    <h5>System</h5>
                  </div>
                </div>
                <div className={showOutput ? "section-content-command" : "section-content-command-update"}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <button
                        type="button"
                        className="btn btn-custom btn-main btn-modal-command"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to update operating system on instance ${instanceName}?`)){
                            console.log(commandEnum.UPDATE_SYSTEM)
                            runCommandOnInstance(commandEnum.UPDATE_SYSTEM);
                          }
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
            )}
            {showOutput &&
              <>
                <hr className="divisor-modal" />
                <div className="section-output-modal-command">
                  <div className="section-title">
                    <div className="col-sm-12">
                      <h5>Command Output</h5>
                    </div>
                  </div>
                  <div className="section-content-final">
                    <div className="row">
                      <div className="col-12 output-command">
                        {contentOuput}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default InstanceCommandsModal;
