// interface Option {
//     value: string;
//     label: string;
// }

// interface CustomDropdownProps {
//     options: Option[];
// }


import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ROLE, useAuth } from "../../auth/AuthContext";

const DropdownMenu = () => {
  const { canUser } = useAuth();

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className="dropdown" ref={dropdownRef}>
    <button
      className="btn btn-light me-3 plus-button"
      style={{ cursor: 'pointer' }}
      onClick={toggleDropdown}
    >
      <span style={{ fontSize: '32px' }}>+</span>
    </button>
    {dropdownOpen && (
      <div className="dropdown-menu dropdown-addInstance" style={{ display: 'block'/* , transform: 'translateX(-50px)' */ }}>
        <Link 
          className="dropdown-item option-addInstance" 
          to="instances/add" 
          title="Create LMS"
          onClick={closeDropdown}
        >
          LMS
        </Link>
        {canUser([ROLE.SUPERADMIN]) && 
          (<Link 
            className="dropdown-item option-addInstance" 
            to="instances/add-shared-server" 
            title="Create Shared Server"
            onClick={closeDropdown}
          >
            Silo
          </Link>)}
      </div>
    )}
  </div>
  );
};

export default DropdownMenu;
