import { ActionFunctionArgs, redirect } from "react-router-dom";
import { AxiosError } from "axios";
import { api } from "../../api";

const action = async ({ request }: ActionFunctionArgs) => {
  console.log("entre al action");

  const formData = await request.formData();
  const twoFactor = formData.get("twoFactor");
  console.log(twoFactor);

  // validate fields
  let dataError = { error: false, fieldError: false };
  if (!twoFactor || (twoFactor && twoFactor.toString().trim() === "")) {
    dataError.error = true;
    dataError.fieldError = true;
  }
  // return data if we have errors
  if (dataError.error) return dataError;

  try {
    const { data } = await api.post("/otp/setup-step-two", {
      twoFactor: twoFactor,
    });

    if (data.validated) return redirect("/");
    else return { error: false, messagge: data.message };
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const response = error.response;
      if (response && response.data) {
        const { data } = response;

        if (response.status === 400 && data?.error && data?.fieldError)
          return { error: true, fieldError: true };

        //not authorized error
        if (response.status === 400 && data?.error) return redirect("/");

        //another error
        return { error: true, twoFactor: twoFactor, messagge: data.message };
      }
      //another error
      return { error: true, twoFactor: twoFactor, messagge: error.message };
    }
  }
};

export default action;
