export type StackedBarChartData = {
    year: number;//string;
    Enero?: number;
    Febrero?: number;
    Marzo?: number;
    Abril?: number;
    Mayo?: number;
    Junio?: number;
    Julio?: number;
    Agosto?: number;
    Septiembre?: number;
    Octubre?: number;
    Noviembre?: number;
    Diciembre?: number;
    total?: number;
}

export type MonthlyBarChartData = {
    month: number;
    amount: number;
}

export type DailyBarChartData = {
    dayOfMonth: number;
    amount: number;
}

export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];