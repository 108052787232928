import { FC } from "react";
import VolumeRow from "./VolumeRow";
import { useAuth, ROLE } from "../../auth/AuthContext";

type VolumeType = {
  instanceId: string;
  volumeId: string;
  partition: string;
  size: number;
  client: string;
  domain: string;
  sslState: string;
  lms: string;
  pendingDelete: boolean;
  version: string;
  versionBuild: string;
  isMoodleData: boolean;
};

interface VolumeTableProps {
  volumes: VolumeType[];
  instanceIp: string;
  instanceDeployment: string;
  onResize: (volume: VolumeType, size: number) => Promise<void>;
  onDelete: (volumeId: string) => Promise<void>;
  onBackup: (
    instanceId: string,
    volumeDomain: string,
    volumeName: string
  ) => Promise<void>;
  onVolumeTags: (volumeSelected: VolumeType) => Promise<void>;
}

const VolumeTable: FC<VolumeTableProps> = ({
  volumes,
  instanceIp,
  instanceDeployment,
  onResize,
  onDelete,
  onBackup,
  onVolumeTags,
}) => {
  const { canUser } = useAuth();

  return (
    <>
      {volumes && volumes.length > 0 && (
        <div className="table-responsive">
          <table
            id="volumes-table"
            className=" table table-bordered table-hover table-sm align-middle w-100 text-wrap"
          >
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">PARTITION</th>
                <th scope="col">VERSION</th>
                <th scope="col">CLIENT</th>
                <th scope="col">SSL</th>
                <th scope="col">SIZE</th>
                <th scope="col">USAGE</th>
                {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
                  <th scope="col">RESIZE</th>
                ) : null}
                {instanceDeployment === "shared" && <th scope="col">ACTIONS</th>}
              </tr>
            </thead>
            <tbody>
              {volumes?.map((volume: VolumeType) => (
                <VolumeRow
                  key={volume.volumeId}
                  instanceIp={instanceIp}
                  instanceDeployment={instanceDeployment}
                  volume={volume}
                  onDelete={onDelete}
                  onResize={onResize}
                  onBackup={onBackup}
                  onVolumeTags={onVolumeTags}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default VolumeTable;
