import { ActionFunctionArgs } from "react-router-dom";

const loader = async ({ params }: ActionFunctionArgs) => {
  const id = params.id;
  const name = params.name;

  return {
    id: id,
    name: name
  };
};

export default loader;
