import { useEffect, useState } from "react";
import {
  Form,
  useActionData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";
import { ROLE, useAuth } from "../../auth/AuthContext";

type SiloType = {
  name: string;
  instanceId: string;
};

export type IActionAddSharedServer = {
  error: boolean;
  startCreationProcess: boolean;
  deployId: string;
  fieldsErrors?: {
    // Will be added more fields if needed
    instanceName?: boolean;
  };
  message?: string;
};

const AddSharedServer = () => {
  const { canUser } = useAuth();

  const navigation = useNavigation();
  const navigate = useNavigate();

  const actionData = useActionData() as IActionAddSharedServer;
  let startCreationProcess = actionData?.startCreationProcess
    ? actionData?.startCreationProcess
    : false;
  const outputFileId: string = actionData?.deployId ? actionData?.deployId : "";
  console.log("response del action: ", actionData);
  console.log("outputFileId: ", outputFileId);
  console.log("startCreationProcess: ", startCreationProcess);

  const region =
      window.localStorage.getItem("moodle-console-region") ?? "us-west-2";

  const [isCreatingComponent, setIsCreatingComponent] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [nextServerName, setNextServerName] = useState<string>("");
  const [contentOuput, setContentOuput] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState('');

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [showOutput, setShowOutput] = useState<boolean>(false);

  function getNextMoodleServerName(servers: SiloType[]): string {
    // Filters names starting with 'MDL_MOODLE' and extracts only the names
    const moodleServerNames = servers
      .filter(server => server.name.startsWith('MDL_MOODLE'))
      .map(server => server.name);
  
    // Sort the names alphabetically
    moodleServerNames.sort();
  
    // Get the name for the new server automatically
    let nextServerName = '';
    if (moodleServerNames.length > 0) {
      const lastServerName = moodleServerNames.slice(-1)[0];
      const matchResult = lastServerName.match(/\d+$/);
      const lastNumber = matchResult !== null ? parseInt(matchResult[0]) : 0;
      const nextNumber = lastNumber + 1;
      nextServerName = `MDL_MOODLE${nextNumber.toString().padStart(2, '0')}`;
    } else {
      nextServerName = 'MDL_MOODLE01'; // If no server exists, start with 01
    }
  
    return nextServerName;
  }

  useEffect(() => {
    if (isCreatingComponent) {
      setIsLoading(true); //show loading element

      //Getting shared servers data
      api
          .get(`/instances/silos?region=${region}`)
          .then(({ data }) => {
            console.log("data recibida de tags en useEffect: ", data);
            if (data.success) {
              const servers: SiloType[] =  data.silos;
              console.log("silos: ", servers);
              const nextServerName = getNextMoodleServerName(servers);
              setNextServerName(nextServerName);
              setIsLoading(false);
            }
          })
          .catch((err: AxiosError) => {
            setIsLoading(false);
            if (err.response) {
              const result = err.response.data as any;
              if (result && result.message) {
                setErrorMessage(result.message);
              }
            } else {
              setErrorMessage("An error has occurred. Failed to load servers information.");
            }
          });

      setIsCreatingComponent(false);
    }
  }, [isCreatingComponent, region]);

  useEffect(() => {
    setIsCreating(startCreationProcess);
    if (startCreationProcess) setShowOutput(true);
  }, [startCreationProcess]);

  useEffect(() => {
    console.log("useEffect from AddSharedServer");

    if (isCreating) {
      let timeout: number;

      const loopRequest = () => {
        timeout = window.setTimeout(() => {
          const url = `/instance/creation-process-output?outputFileId=${outputFileId}`;
          api
            .get(url)
            .then(async (response) => {
              const output = response.data;
              if (response.status === 200) {
                //finished successfully
                setIsCreating(false);
                setContentOuput(output);
              } else if (response.status === 206) {
                //continue the process
                setContentOuput(output);
                loopRequest();
              }
            })
            .catch((error) => {
              // do something with the error
              console.log(error.message);
              setIsCreating(false);
              setErrorMessage(error.message);
            });
        }, 3000);
      };

      loopRequest();

      return () => {
        if (typeof timeout === "number") clearTimeout(timeout);
      };
    }
  }, [isCreating, outputFileId]);

  return (
    <div className="container ">
      <div
        className={
          isLoading || navigation.state === "loading" || navigation.state === "submitting"
            ? "loading"
            : ""
        }
      ></div>
      <div className="row">
        <div className="col-12 pt-3">
          <h2 id="add-instance-title">Add Shared Server</h2>
          <div className="row mt-4 add-instance-container">
            <div className="col-6 col-md-6 col-lg-5">
              <h4>Server Information</h4>
              <Form
                method="post"
                className="add-form"
                onSubmit={(event) => {
                  if (
                    !window.confirm(
                      "Please confirm you want to create new shared server?"
                    )
                  ) {
                    event.preventDefault();
                  }
                }}
              >
                <div id="add-instance-form" className="mt-3">
{/*                   <div className="mb-3">
                    <div>
                      <label htmlFor="sharedServerCheck" className="form-label">
                        Would you like to set up a shared server?
                      </label>
                    </div>
                    <div className="form-check shared-server">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="sharedServer"
                        name="sharedServerCheck"
                        id="sharedServerCheck"
                        onChange={(e) => setIsSharedServer(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Shared Server
                      </label>
                    </div>
                  </div> */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="instanceNameInput">
                      Instance Name
                    </label>
                    <input
                      className="form-control"
                      id="instanceNameInput"
                      name="instanceName"
                      type="text"
                      aria-describedby="nameHelp"
                      readOnly={true}
                      value={nextServerName}
                    />
                   {actionData?.error && actionData?.fieldsErrors?.instanceName && (
                      <div id="nameHelp" className="form-text field-error">
                        Invalid instance name
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="phpVersionInput">
                      PHP Version
                    </label>
                    <input
                      className="form-control"
                      id="phpVersionInput"
                      name="phpVersion"
                      type="text"
                      aria-describedby="phpVersionHelp"
                      readOnly={true}
                      value="8.1"
                    />
                  </div>
                  <div className="mb-3 text-end">
                    {!showOutput && canUser([ROLE.SUPERADMIN]) && (
                      <button type="submit" className="btn btn-primary me-3">
                        Create new shared server
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => navigate(-1)}
                    >
                      Go back
                    </button>
                  </div>
                  {errorMessage && (
                    <div className="mt-3">
                      <p>{errorMessage}</p>
                    </div>
                  )} 
{/*                   {errorMessage && (
                    <div className="mt-3">
                      <p>{errorMessage}</p>
                    </div>
                  )} */}
                </div>
              </Form>
            </div>
            {showOutput && (
              <div className="col-6 col-md-6 col-lg-5">
                <h4>Shared Server Creation Process</h4>
                <div
                  id="addInstace-output-board"
                  className="form-control w-100 mt-3"
                >
                  {contentOuput}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSharedServer;
