import { redirect } from "react-router-dom";
import { api } from "../../api";

const loader = async () => {
  try {
    const { data } = await api.post("/otp/setup-step-one");

    return data;
  } catch (error) {
    return redirect("/");
  }
};

export default loader;
