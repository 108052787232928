import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";

interface ILoaderBackup {
  region: string;
  uniqueId: string;
}

const Backup = () => {
  const data = useLoaderData() as ILoaderBackup;
  console.log("data del loader: ", data);

  const navigate = useNavigate();

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  function downloadURI(uri: string) {
    var link = document.createElement("a");
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    //delete link;
  }

  useEffect(() => {
    if (data.uniqueId) {
      api
        .get(`/backup?fileUniqueId=${data.uniqueId}&region=${data.region}`)
        .then(({ data }) => {
          const backupDownloadURL = data.backupURL;
          downloadURI(backupDownloadURL);
        })
        .catch((err) => {
          console.log(">>>", err.message);
          if (
            err instanceof AxiosError &&
            err.response &&
            err.message === "Not authorized"
          ) {
            console.log("error 401");
            window.localStorage.setItem(
              "moodle-console-urlBackup",
              `backups/${data.uniqueId}`
            );
            navigate("/");
          } else {
            setIsError(true);
            setErrorMessage(err.message);
          }
        });
    }
  }, [data, navigate]);

  return (
    <div className="container">
      {/* <div className={ (navigation.state === "loading" || navigation.state === "submitting") ? "loading" : "" }></div> */}
      {isError && errorMessage && (
        <div className="mt-3">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default Backup;
