import { ActionFunctionArgs, redirect } from "react-router-dom";
import { AxiosError } from "axios";
import { api } from "../../api";

const action = async ({ request }: ActionFunctionArgs) => {
  const urlDelete = window.localStorage.getItem("moodle-console-urlDelete");
  const urlBackup = window.localStorage.getItem("moodle-console-urlBackup");
  const instanceRequest = window.localStorage.getItem("moodle-console-instance-request");
  console.log("urlDelete:", urlDelete);
  console.log("instanceRequest:", instanceRequest);

  const formData = await request.formData();
  const username = formData.get("username");
  const password = formData.get("password");
  const twoFactor = formData.get("twoFactor");
  //console.log(username, password, twoFactor);

  // validate fields
  let data = {
    error: false,
    isEnabledTwoFactor: false,
    fieldsErrors: { username: false, password: false, twoFactor: false },
  };
  if (!username || (username && username.toString().trim() === "")) {
    data.error = true;
    data.fieldsErrors.username = true;
  }
  if (!password || (password && password.toString().trim() === "")) {
    data.error = true;
    data.fieldsErrors.password = true;
  }

  // return data if we have errors
  if (data.error) {
    return data;
  }

  // otherwise authenticate
  let accessToken;
  if (!twoFactor) {
    //If there is no twoFactor just authenticate user and pass
    try {
      const { data } = await api.post("/signin", {
        username: username,
        password: password,
      });

      //save accessToken in localStorage
      accessToken = data.accessToken;
      window.localStorage.setItem("moodle-console-accessToken", accessToken);

      if (!data.twoFactorAuthEnabled) return redirect("/qrcode");
      else
        return {
          error: false,
          username,
          password,
          isEnabledTwoFactor: data.twoFactorAuthEnabled,
        };
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const { response } = error;
        if (response && response.data) {
          const { data } = response;
          console.log("response !ok: ", data);

          //incorrect username error
          if (
            response.status === 400 &&
            data?.error &&
            data?.fieldError &&
            data?.username
          )
            return {
              error: true,
              isEnabledTwoFactor: false,
              fieldsErrors: { username: true },
            };

          //incorrect password error
          if (
            response.status === 400 &&
            data?.error &&
            data?.fieldError &&
            data?.password
          )
            return {
              error: true,
              isEnabledTwoFactor: false,
              fieldsErrors: { password: true },
            };

          //another error
          return { error: true, username, password, messagge: data.message };
        }
      }
    }
  } else {
    // Verify two factor
    try {
      const response = await api.post("/otp/validate", {
        token: twoFactor,
      });

      if (response.data.validated) {
        if (
          (!urlDelete || (urlDelete && urlDelete.toString().trim() === "")) &&
          (!urlBackup || (urlBackup && urlBackup.toString().trim() === "")) &&
          (!instanceRequest || (instanceRequest && instanceRequest.toString().trim() === ""))
        )
          return redirect("/home");

        if (urlDelete) {
          window.localStorage.setItem("moodle-console-urlDelete", ``);
          return redirect(urlDelete);
        }

        if (urlBackup) {
          window.localStorage.setItem("moodle-console-urlBackup", ``);
          return redirect(urlBackup);
        }

        if(instanceRequest){
          console.log("Existe instanceRequest");
          window.localStorage.removeItem("moodle-console-instance-request");
          console.log('Local Storage: ', window.localStorage.getItem("moodle-console-instance-request"));
          return redirect(`/home/instances/confirm-add/${instanceRequest}`);
        }
      } else
        return {
          error: false,
          username,
          password,
          isEnabledTwoFactor: true,
          fieldsErrors: { twoFactor: true },
        };
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const { response } = error;
        if (response && response.data) {
          const { data } = response;
          console.log("response !ok: ", data);

          //incorrect username error
          if (
            response.status === 400 &&
            data?.error &&
            data?.fieldError &&
            data?.username
          )
            return {
              error: true,
              isEnabledTwoFactor: false,
              fieldsErrors: { username: true },
            };

          //incorrect password error
          if (
            response.status === 400 &&
            data?.error &&
            data?.fieldError &&
            data?.password
          )
            return {
              error: true,
              isEnabledTwoFactor: false,
              fieldsErrors: { password: true },
            };

          //not authorized error
          if (response.status === 400 && data?.error) return redirect("/");

          //another error
          return { error: true, username, password, messagge: data.message };
        }
      }
    }
  }
};

export default action;
