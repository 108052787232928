export type InstanceDataType = {
    id: string;
    name: string;
    ip: string;
    domain: string;
    deployment: string;
    lms: string;
}

export enum PeriodType {
    MONTHLY = "1month",
    SEMESTRAL = "6months",
    GLOBAL = "global",
}