import { useEffect, useState } from "react";
import {
  useNavigate,
  // useNavigation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";
import DatabasesTable from "./DatabasesTable";

const DBInstanceInformationModal = () => {
  const params = useParams();
  const dbInstanceId = params.dbInstanceId;
  const [databasesList, setDatabasesList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  // const navigation = useNavigation();
  const region = useOutletContext();
  const [isCreatingPage, setIsCreatingPage] = useState<boolean>(true);

  useEffect(() => {
    console.log("useEffect de DBInstanceInformationModal: ", region);

    if (isCreatingPage) {
      setIsLoading(true); //show loading element

      // Getting databases list
      api
        .get(`/dbinstances/${dbInstanceId}/databases?region=${region}`)
        .then(({ data }) => {
          //console.log("data recibida de bases de datos: ", data);
          if (data.success && data.databases && data.databases.length > 0) {
            setDatabasesList(data.databases);
          }
          setIsLoading(false); //hide loading element
        })
        .catch((err) => {
          if (err instanceof AxiosError && err.response) {
            setError(true);
            setErrorMessage(err.response.data.message);
          } else if (err.error && err.message) {
            setError(true);
            setErrorMessage(err.message);
          } else {
            setError(true);
            setErrorMessage(
              "An error has occurred. Failed to load databases list from dbinstance."
            );
          }
          setIsLoading(false); //hide loading element
        });

      setIsCreatingPage(false);
    }
  }, [isCreatingPage, dbInstanceId, region]);

  return (
    <>
      <div className="modal-overlay">
        {/* <div className={isLoading || navigation.state === "submitting" ? "loading" : "" } ></div> */}
        <div className={isLoading ? "loading" : "" } ></div>
        <div className="modal-actions information-databases">
          <button
            className="close-modal-button"
            onClick={() => {
              navigate(-1);
            }}
          >
            x
          </button>
          <h4 className="title-modal-information">Databases</h4>
          <div className="modal-actions-body">
            <div className="section-modal-dbsnap">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>{dbInstanceId}</h5>
                </div>
              </div>
              <div className="section-content databases">
                {error && errorMessage
                  ?
                  (
                    <div className="pathMessage">
                      <p>{errorMessage}</p>
                    </div>
                  )
                  :
                  (<DatabasesTable databases={databasesList} />)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DBInstanceInformationModal;
