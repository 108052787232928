import { ActionFunctionArgs } from "react-router-dom";

const loader = ({ params }: ActionFunctionArgs) => {
  return {
    instanceId: params.instanceId,
    volumeId: params.volumeId,
    volumeName: params.volumeName,
  };
};

export default loader;
