import { FC } from "react";

type DBSnapshotType = {
    dbSnapshotId: string;
    dbSnapshotDate: string;
    dbInstanceId: string;
}

interface DBSnapshotTableProps {
    dbSnapshots: DBSnapshotType[];
  }

const DBSnapshotTable: FC<DBSnapshotTableProps> = ({ dbSnapshots }) => {

    return (
        <>
        { dbSnapshots && dbSnapshots.length > 0 &&
        <div className="row">
            <div className="col-12 text-center scrollit-dbsnap">
                <table id="dbsnap-table" className=" table table-bordered table-hover table-sm align-middle w-100 text-wrap" >
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">CREATION DATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dbSnapshots?.map((dbSnapshot: DBSnapshotType) => (
                            <tr key={ dbSnapshot.dbSnapshotId }>
                                <td>{ dbSnapshot.dbSnapshotId }</td>
                                <td>{ dbSnapshot.dbSnapshotDate }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        }
        </>
    );
}

export default DBSnapshotTable;