import { useState } from "react";
import {
  useLoaderData,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";
import { useAuth, ROLE } from "../../auth/AuthContext";

enum actionEnum {
  START = "start-lms",
  STOP = "stop-lms"
}

interface ILoaderModal {
  instanceId: string;
  instanceIp: string;
  volumeId: string;
  volumeName?: string;
  volumeDomain?: string;
}

const LmsActionsModal = () => {
  const loader = useLoaderData() as ILoaderModal;
  const region = useOutletContext();
  const { canUser } = useAuth();

  const instanceId = loader.instanceId;
  const instanceIp = loader.instanceIp;
  const volumeId = loader.volumeId;
  const volumeName = loader.volumeName;
  const volumeDomain = loader.volumeDomain;
  console.log('lms modal: ', loader)

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const executeAction = async (action: actionEnum) => {

    if (!instanceId || !instanceIp || !volumeId || !volumeDomain) {
      alert("Invalid parameters.");
      return;
    }

    try {
      setIsLoading(true);
      // Execute action to LMS
      const { data } = await api.post(`/instances/${instanceId}/volumes/${volumeId}/action`, {
        instanceIp: instanceIp,
        domain: volumeDomain,
        action: action,
        region: region,
      });

      console.log("response ok: ", data);
      setIsLoading(false);
      if (data && data.success)
        alert("The operation was executed successfully.");
      else 
        alert("An error occurred while executing the operation.");

    } catch (error: any) {
      if (error instanceof AxiosError && error.response) {
        const { data } = error.response;
        if (data.message) alert(data.message);
      } else alert(error.message);

      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="modal-overlay-second">
        <div className={isLoading  ? "loading" : "" }></div>
        <div className="modal-actions">
          <h4 className="title-modal-lms-actions">{volumeName}</h4>
          <button
            className="close-modal-button"
            onClick={() => navigate(-1)}
          >
            x{" "}
          </button>
          <div className="modal-actions-body">
            <div className="section-modal-lms-action">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>Actions</h5>
                </div>
              </div>
              {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
              <div className="section-content-lms-actions">
                <div className="row mb-4">
                  <div className="col-sm-6 col-12">
                    <button
                      type="button"
                      className="btn btn-custom btn-main btn-modal-action"
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you want to perform the operation Start on LMS ${volumeName}?`
                          )
                        )
                          executeAction(actionEnum.START);
                      }}
                    >
                      Start
                    </button>
                  </div>
                  <div className="col-sm-6 col-12">
                    <button
                      type="button"
                      className="btn btn-danger btn-main btn-modal-action"
                      onClick={(e) => {
                        if (
                          window.confirm(
                            `Are you sure you want to perform the operation Stop on LMS ${volumeName}?`
                          )
                        )
                          executeAction(actionEnum.STOP);
                      }}
                    >
                      Stop
                    </button>
                  </div>
                </div>
              </div>
              ) : 'You do not have permission to perform this action.' }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LmsActionsModal;
