import { ActionFunctionArgs } from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";

const action = async ({ request, params }: ActionFunctionArgs) => {
  const region =
    window.localStorage.getItem("moodle-console-region") ?? "us-west-2";
  console.log("region del localstorage: ", region);

  const instanceName = params.instanceName;
  const instanceId = params.instanceId;
  console.log("entre al action con: ", instanceId, instanceName);

  const formData = await request.formData();
  const instanceNameInput = formData.get("instanceName");
  console.log("instanceNameForm: ", instanceNameInput);

  const noDeleteDomain = formData.get("noDeleteDomain");
  console.log("noDeleteDomain: ", noDeleteDomain);

  // Convert the value to a boolean
  const noDeleteDomainChecked = noDeleteDomain === "on";
  console.log("noDeleteDomainChecked: ", noDeleteDomainChecked);

  // validate fields
  let dataError = { error: false, fieldError: false };
  if (
    !instanceNameInput ||
    (instanceNameInput && instanceNameInput.toString().trim() === "") ||
    instanceNameInput !== instanceName
  ) {
    dataError.error = true;
    dataError.fieldError = true;
  }
  // return data if we have errors
  if (dataError.error) return dataError;

  //otherwise
  try {
    const { data } = await api.post(`/instances/delete-request`, {
      resources: {
        instanceId: instanceId,
      },
      deleteLMS: false,
      region: region,
      deleteDomain: !noDeleteDomainChecked, // If noDeleteDomainChecked is true, set deleteDomain to false and vice versa
    });

    console.log("response: ", data);

    alert("The deletion request has been completed successfully.");
    return { success: true };
  // } catch (err) {
  //   alert("The deletion request could not be completed.");
  //   return { success: false };
  // }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      alert(`${error.response.data.message}. The deletion request could not be completed.`);
      return { success: false };
    } else {
      alert("The deletion request could not be completed.");
      return { success: false };
    }
  }
  
};

export default action;
