import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useGlobalContext } from "../../GlobalContext";
import DBInstanceRow from "./DBInstanceRow";
import { api } from "../../api";
import { AxiosError } from "axios";
import "../css/App.css";
import { dbInstanceType } from "./types";

const DBInstanceTable = () => {
  const [dbInstanceList, setDBInstanceList] = useState<dbInstanceType[]>([]);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { region, filterSearch } = useGlobalContext();
  //console.log("region del context: ", region);
  //console.log("filterSearch del context: ", filterSearch);

  useEffect(() => {
    console.log("useEffect from DBInstanceTable");
    let isSubscribed = true;
    setIsError(false);
    setErrorMessage("");

    setIsLoading(true);
    api
      .get(`/dbinstances?region=${region}`)
      .then(({ data }) => {
        console.log(data);
        if (isSubscribed) {
          console.log("dbinstances: ", data.dbinstances);
          setDBInstanceList(data.dbinstances);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsError(true);
        setErrorMessage(
          err instanceof AxiosError && err.response
            ? err.response.data.message
            : err.message
        );
        setIsLoading(false);
      });

    // cancel any future set state
    return () => {
      isSubscribed = false;
    };
  }, [region]);

  let filteredDBInstances = dbInstanceList.filter(function (
    dbInstance: dbInstanceType
  ) {
    if (!filterSearch) return true;

    const hasName =
      dbInstance.dbInstanceId &&
      dbInstance.dbInstanceId.toLowerCase().includes(filterSearch.toLowerCase());
    const engine = `${dbInstance.engine} ${dbInstance.engineVersion}`;
    const hasEngine = 
      engine &&
      engine.includes(filterSearch);
    const hasState = dbInstance.state
      .toLowerCase()
      .includes(filterSearch.toLowerCase());
    const hasType = dbInstance.dbInstanceClass
      .toLowerCase()
      .includes(filterSearch.toLowerCase());

    return (
      hasName ||
      hasEngine ||
      hasState ||
      hasType
    );
  });

  if (isError && errorMessage)
    return (
      <div className="errorMessage">
        <p>{errorMessage}</p>
      </div>
    );
  else
    return (
      <div className="row">
        <div className={isLoading ? "loading" : ""}></div>
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-dbinstances" id="main-table">
              <thead>
                <tr>
                  <th scope="col">State</th>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Engine</th>
                  <th scope="col">Storage</th>
                  <th scope="col">Usage Storage</th>
                  <th scope="col" /* colSpan={3} */>Settings</th>
                </tr>
              </thead>
              <tbody>
                {filteredDBInstances &&
                  filteredDBInstances.length > 0 &&
                  filteredDBInstances.map((dbinstance: dbInstanceType) => (
                    <DBInstanceRow
                      key={dbinstance.dbInstanceId}
                      dbinstance={dbinstance}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {<Outlet context={region} />}
      </div>
    );
};

export default DBInstanceTable;
