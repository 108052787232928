import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";

enum actionEnum {
  START = "start",
  STOP = "stop",
  REBOOT = "reboot",
}

const DBInstanceActionsModal = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dbInstanceId = params.dbInstanceId;
  console.log("dbInstanceId del loader: ", dbInstanceId);

  if (typeof dbInstanceId === "undefined")
    throw new Error("dbInstanceId can not be undefined.");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reasonError, setReasonError] = useState<boolean>(false);
  const reasonTextAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleActionClick = (instanceId: string, action: actionEnum) => {
    const reasonTextArea = reasonTextAreaRef.current;
    if (!reasonTextArea) {
      setReasonError(true);
      return;
    }
  
    const reasonText = reasonTextArea.value.trim();
    const wordCount = reasonText.split(/\s+/).length;
  
    if (reasonText === '' || wordCount < 5) {
      setReasonError(true);
      return;
    }
  
    setReasonError(false);
  
    if (window.confirm(`Are you sure you want to perform the operation ${action} on dbinstance ${dbInstanceId}?`)) {
      executeActionOnInstance(instanceId, action, reasonTextArea);
    }
  };
  

  const executeActionOnInstance = async (
    dbInstanceId: string,
    action: actionEnum,
    reasonTextArea: HTMLTextAreaElement
  ) => {
    const region =
      window.localStorage.getItem("moodle-console-region") ?? "us-west-2";
    console.log("region del localstorage: ", region);
    console.log("dbInstanceId: ", dbInstanceId);
    console.log("action: ", action);

    setIsLoading(true);
    // Execute action to instance
    try {
      const response = await api.post(`/dbinstances/${dbInstanceId}/action`, {
        action: action,
        region: region,
        reason: reasonTextArea.value.trim()
      });
      const data = response.data;
      if (data.success) {
        setIsLoading(false);
        console.log("response ok: ", data);
        if (data.success){
          alert(`The operation ${action} was executed successfully.`);
          reasonTextArea.value = '';
        }
        else 
          alert("Something went wrong during the process.");
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const { data } = error.response;
        setIsLoading(false);
        console.log("response !ok: ", data);

        //send details error
        if (data.error && data.message) {
          alert(data.message);
        } else alert("Something went wrong during the process.");
      }
    }
  };

  return (
    <>
      <div className="modal-overlay">
        <div className={isLoading ? "loading" : ""}></div>
        <div className="modal-actions">
          <h4 className="title-modal-actions">Actions</h4>
          <button className="close-modal-button" onClick={() => navigate(-1)}>
            x{" "}
          </button>
          <div className="modal-actions-body">
            <div className="section-modal-rds">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>General</h5>
                </div>
              </div>
              <div className="section-action-content">
                <div className="row mb-4">
                  <div className="col-12">
                    <label className="form-label label-reason" htmlFor="reasonTextArea">Motive</label>
                    <textarea
                      id="reasonTextArea"
                      name="reason"
                      className={`form-control ${reasonError ? 'wrong' : ''}`}
                      maxLength={255}
                      rows={3}
                      ref={reasonTextAreaRef}
                    />
                    {reasonError && <div className="form-text field-error">The motive must be at least 5 words.</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4 col-12">
                    <button
                      type="button"
                      className="btn btn-success btn-main btn-modal-action"
                      onClick={() => handleActionClick(dbInstanceId, actionEnum.START)}
                    >
                      Start
                    </button>
                  </div>
                  <div className="col-sm-4 col-12">
                    <button
                      type="button"
                      className="btn btn-danger btn-main btn-modal-action"
                      onClick={() => handleActionClick(dbInstanceId, actionEnum.STOP)}
                    >
                      Stop
                    </button>
                  </div>
                  <div className="col-sm-4 col-12">
                    <button
                      type="button"
                      className="btn btn-warning btn-main btn-modal-action"
                      onClick={() => handleActionClick(dbInstanceId, actionEnum.REBOOT)}
                    >
                      Reboot
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DBInstanceActionsModal;
