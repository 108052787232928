import React, { useEffect, useState } from 'react';
import { useAuth, ROLE } from '../../../auth/AuthContext';
import { api } from "../../../api";

interface DomainRowProps {
    dynamoInstanceId: string;
    url: string;
    onDelete: (dynamoInstanceId: string, domain: string) => void;
}

interface DomainDynamoData {
    canDeleteDomain: boolean;
}

let dynamoDomainCache: any = {};

const DomainRow: React.FC<DomainRowProps> = ({ dynamoInstanceId, url, onDelete}) => {
    const { canUser } = useAuth();
    const [canDeleteDomain, setCanDeleteDomain] = useState<boolean>(false);

    useEffect(() => {
        const endpoint = `/dynamo-instances/${dynamoInstanceId}/domains/${url.replace(/^https?:\/\//, '')}/data`;
        const updateState = (data: DomainDynamoData) => {
            setCanDeleteDomain(data.canDeleteDomain);
        };
    
        //if (dynamoDomainCache[endpoint]) return updateState(dynamoDomainCache[endpoint]);
    
        const controller = new AbortController();
        api
          .get<DomainDynamoData>(endpoint, { signal: controller.signal })
          .then(({ data }) => {
            console.log("data de ", url, data);
            dynamoDomainCache[endpoint] = data;
            updateState(data);
          })
          .catch(() => {});
    
        return () => {
          controller.abort();
        };
    }, [url, dynamoInstanceId]);

    return (
        <tr>
            <td>{url}</td>
            {canUser([ROLE.SUPERADMIN]) ? (
                <td>
                    { canDeleteDomain ? (
                    <a
                        href="/#"
                        title='Delete domain from DynamoDB'
                        onClick={(e) => {
                            e.preventDefault();
                            if (window.confirm(`Please confirm you want to delete the domain ${url} ?`)) {
                                onDelete(dynamoInstanceId, url);
                            }
                        }}
                    >
                        <i className="fa-solid fa-trash-can text-danger"></i>
                    </a>
                    ) : 'Cannot delete domain'
                    }
                </td>
            ) : null}
        </tr>
    );
};

export default DomainRow;