import {
  FC,
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
  useContext,
  useCallback
} from "react";

export enum ROLE {
  ADMIN = "admin",
  SUPERADMIN = "superadmin",
  MANAGER = "manager",
}

interface User {
  name: string;
  role: string;
}

interface AuthContextType {
  user: User | null;
  saveUser: (user: User) => void;
  canUser: (allowedRoles: ROLE[]) => boolean;
}

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const saveUser = useCallback((user: User) => {
    setUser(user);
  }, []);
  const canUser = useCallback((allowedRoles: ROLE[]) => {
    if (!user) return false;
    return allowedRoles.includes(user.role as ROLE);
  }, [user]);

  const value = useMemo(() => {
    return { user, saveUser, canUser };
  }, [user, saveUser, canUser]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
