import { useRef, useState } from "react";
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigate,
  useNavigation,
  useOutletContext,
} from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";
import { useAuth, ROLE } from "../../auth/AuthContext";

type InstanceDataType = {
  ip: string;
  domain: string;
  dbHost: string;
  dbName: string;
  dbUser: string;
  dbPass: string;
  moodledataDir: string;
  appDir: string;
};

enum actionEnum {
  START = "start",
  STOP = "stop",
  REBOOT = "reboot",
}

interface IActionModal {
  instanceName?: string;
  fieldError?: boolean;
  error?: boolean;
  messagge?: string;
  success: boolean;
}

interface ILoaderModal {
  instanceId: string;
  instanceName: string;
  instanceDeployment: string;
}

const InstanceActionsModal = () => {
  const loader = useLoaderData() as ILoaderModal;
  const { canUser } = useAuth();
  //const instanceId: any = useLoaderData();

  const instanceId = loader.instanceId;
  const instanceName = loader.instanceName;
  const instanceDeployment = loader.instanceDeployment;
  // console.log("instanceId del loader: ", instanceId);
  // console.log("instanceName del loader: ", instanceName);
  // console.log("instanceDeployment del loader: ", instanceDeployment);

  const { handleRebootPending } = useOutletContext<{ handleRebootPending: (instanceId: string, rebootPending: boolean) => void }>();

  const navigation = useNavigation();

  const dataAction = useActionData() as IActionModal | undefined;
  console.log("response del actionData: ", dataAction);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reasonError, setReasonError] = useState(false);
  const reasonTextAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleActionClick = (instanceId: string, action: actionEnum) => {
    const reasonTextArea = reasonTextAreaRef.current;
    if (!reasonTextArea) {
      setReasonError(true);
      return;
    }
  
    const reasonText = reasonTextArea.value.trim();
    const wordCount = reasonText.split(/\s+/).length;
  
    if (reasonText === '' || wordCount < 5) {
      setReasonError(true);
      return;
    }
  
    setReasonError(false);
  
    if (window.confirm(`Are you sure you want to perform the operation ${action} on instance ${instanceName}?`)) {
      executeActionOnInstance(instanceId, action, reasonTextArea);
    }
  };

  const executeActionOnInstance = async (
    instanceId: string, 
    action: actionEnum, 
    reasonTextArea: HTMLTextAreaElement
  ) => {
    console.log("instanceId: ", instanceId, action);
    const region = window.localStorage.getItem("moodle-console-region");

    try {
      setIsLoading(true);
      // Execute action to instance
      const { data } = await api.post(`/instances/${instanceId}/action`, {
        action: action,
        region: region,
        reason: reasonTextArea.value.trim()
      });

      console.log("response ok: ", data);
      setIsLoading(false);
      if (data.success){
        alert("The operation was executed successfully. Please note that it may take a few minutes for the instance change to be reflected.");
        reasonTextAreaRef.current!.value = '';
        if (action === actionEnum.REBOOT) {
          handleRebootPending(instanceId, false);
        }
      }
    } catch (error: any) {
      setIsLoading(false);

      if (error instanceof AxiosError && error.response) {
        const response = error.response;
        const data = response.data;
        alert(data.message);
      } else alert(error.message);
    }
  };

  const getServerInfoForBackup = async () => {
    const region =
      window.localStorage.getItem("moodle-console-region") ?? "us-west-2";

    try {
      setIsLoading(true);
      // Execute action to instance
      const { data } = await api.get(
        `/server-info/${instanceId}?region=${region}`
      );
      console.log("response getServerInfoForBackup: ", data);

      setIsLoading(false);
      if (data.success) {
        const instanceData: InstanceDataType = data.instanceData;
        console.log("ya puede iniciar el backup.");
        alert(
          "All the necessary data for the backup process have been obtained. The backup process will begin."
        );
        await beginBackup(region, instanceId, instanceName, instanceData);
      }
    } catch (error: any) {
      setIsLoading(false);

      if (error instanceof AxiosError && error.response) {
        const response = error.response;
        const data = response.data;
        alert(data.message);
      } else alert(error.message);
    }
  };

  const beginBackup = (
    region: string,
    instanceId: string,
    instanceName: string,
    instanceData: InstanceDataType
  ) => {
    return api.post(`/backup/${instanceId}`, {
      region: region,
      instanceName: instanceName,
      instanceData: instanceData,
    });
  };

  return (
    <>
      <div className="modal-overlay">
        <div className={ isLoading || navigation.state === "submitting" ? "loading" : "" } ></div>
        <div className="modal-actions">
          <h4 className="title-modal-actions">Actions</h4>
          <button
            className="close-modal-button"
            onClick={() => navigate("/home/instances")}
          >
            x{" "}
          </button>
          <div className="modal-actions-body">
            <div className="section-modal-action">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>General</h5>
                </div>
              </div>
              <div className="section-action-content">
                <div className="row mb-4">
                  <div className="col-12">
                    <label className="form-label label-reason" htmlFor="reasonTextArea">Motive</label>
                    <textarea
                      id="reasonTextArea"
                      name="reason"
                      className={`form-control ${reasonError ? 'wrong' : ''}`}
                      maxLength={255}
                      rows={3}
                      ref={reasonTextAreaRef}
                    />
                    {reasonError && <div className="form-text field-error">The motive must be at least 5 words.</div>}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-sm-4 col-12">
                    <button
                      type="button"
                      className="btn btn-custom btn-main btn-modal-action"
                      onClick={() => handleActionClick(instanceId, actionEnum.START)}
                    >
                      Start
                    </button>
                  </div>
                  <div className="col-sm-4 col-12">
                    <button
                      type="button"
                      className="btn btn-danger btn-main btn-modal-action"
                      onClick={() => handleActionClick(instanceId, actionEnum.STOP)}
                    >
                      Stop
                    </button>
                  </div>
                  <div className="col-sm-4 col-12">
                    <button
                      type="button"
                      className="btn btn-warning btn-main btn-modal-action"
                      onClick={() => handleActionClick(instanceId, actionEnum.REBOOT)}
                    >
                      Reboot
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {instanceDeployment === "dedicated" && canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) && (
              /* Backup and Delete Sections - only in instances standalone */
              <>
                <hr className="divisor-modal-action" />
                <div className="section-modal-action">
                  <div className="section-title">
                    <div className="col-sm-12">
                      <h5>Backup</h5>
                    </div>
                  </div>
                  <div className="section-content">
                    <div className="row mb-4">
                      <div className="col-sm-6">
                        <button
                          className="btn btn-custom btn-main btn-modal-action"
                          onClick={(e) => {
                            if (
                              window.confirm(
                                `Are you sure you want to perform the operation Backup on ${instanceName} instance?`
                              )
                            )
                              getServerInfoForBackup();
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button
                          className="btn btn-danger btn-main btn-modal-action"
                          onClick={() => navigate("/home/instances")}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="divisor-modal-action" />
                <div className="section-modal-action">
                  <div className="section-title">
                    <div className="col-sm-12">
                      <h5>Delete</h5>
                    </div>
                  </div>
                  <div className="section-modal-delete">
                    <Form className="request-delete" method="post">
                      <div className="row mb-1">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="instanceName"
                        >
                          Instance Name
                        </label>
                        <div className="col-sm-7">
                          <input
                            type="text"
                            name="instanceName"
                            className={`${dataAction?.fieldError
                                ? "form-control wrong"
                                : "form-control"
                              }`}
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-7">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="noDeleteDomain"
                              id="noDeleteDomain"
                            />
                            <label className="form-check-label" htmlFor="noDeleteDomain">
                              Do not delete domain
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row text-end mr div-delete-instance">
                        <div className="col-sm-12">
                          <button
                            className="btn btn-danger btn-main"
                            type="submit"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InstanceActionsModal;
