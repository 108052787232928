import { FC, useEffect, useState } from "react";
import { api } from "../../api";
import { useAuth, ROLE } from "../../auth/AuthContext";
import { Link } from "react-router-dom";

interface VolumeRowProps {
  volume: VolumeType;
  instanceIp: string;
  instanceDeployment: string;
  onResize: (volume: VolumeType, size: number) => Promise<void>;
  onDelete: (volumeId: string) => Promise<void>;
  onBackup: (
    instanceId: string,
    volumeDomain: string,
    volumeName: string
  ) => Promise<void>;
  onVolumeTags: (volumeSelected: VolumeType) => Promise<void>;
}

type VolumeType = {
  instanceId: string;
  volumeId: string;
  partition: string;
  size: number;
  client: string;
  domain: string;
  sslState: string;
  lms: string;
  pendingDelete: boolean;
  version: string;
  versionBuild: string;
  isMoodleData: boolean;
};

enum certificateStatusEnum {
  NA = "NO SSL",
  VALID = "VALID",
  EXPIRED = "EXPIRED",
  RENEW = "RENEW",
}

interface VolumeData {
  version?: string;
  sslDaysRemaining?: number;
  storage: string;
}

let volumesCache: any = {};

const VolumeRow: FC<VolumeRowProps> = ({
  volume,
  instanceIp,
  instanceDeployment,
  onResize,
  onDelete,
  onBackup,
  onVolumeTags,
}) => {
  const { canUser } = useAuth();
  const [sslStatus, setsslStatus] = useState(certificateStatusEnum.NA);
  const [lmsVersion, setLMSVersion] = useState<string>("");
  const [storage, setStorage] = useState<string>("");

  const region = window.localStorage.getItem("moodle-console-region");

  const handleResizeChange = (size: number) => {
    if (size === 0) {
      return;
    }
  
    if (window.confirm(`Please confirm you want to increase the ${volume.client} volume size in ${size}GB.`)) {
      console.log('Resizing volume: ', size);
      onResize(volume, size);
    }
    (document.getElementById(`select-resize-${volume.volumeId}`) as HTMLSelectElement).selectedIndex = 0;
  };

  useEffect(() => {
    // const url = `/instances/${volume.instanceId}/volumes/${volume.volumeId}/data-volume?region=${region}&ip=${instanceIp}&lms=${volume.lms}&domain=${volume.domain.replace(/^https?:\/\//, '')}&deployment=${instanceDeployment}&volumeDeviceName=${volume.partition}`;

    const endpoint = `/instances/${volume.instanceId}/volumes/${volume.volumeId}/data-volume-dedicated?region=${region}&ip=${instanceIp}&volumeDeviceName=${volume.partition}`;
    const sharedEndpoint = `/instances/${volume.instanceId}/volumes/${volume.volumeId}/data-volume?region=${region}&ip=${instanceIp}&lms=${volume.lms}&domain=${volume.domain.replace(/^https?:\/\//, '')}&deployment=${instanceDeployment}&volumeDeviceName=${volume.partition}`;
    const url = instanceDeployment === "shared" ? sharedEndpoint : endpoint;
    //console.log('url:', url);

    const updateState = (data: VolumeData) => {
      //console.log(`${volume.client}`, data)
      if (data.storage) setStorage(data.storage);
      if (data.version) {
        setLMSVersion(data.version);
        volume.version = data.version;
      }
      if (data.sslDaysRemaining)
        setsslStatus(getCertificateStatus(data.sslDaysRemaining));
    };

    if (volumesCache[url]) return updateState(volumesCache[url]);

    //if (volume.domain) {
      const controller = new AbortController();
      api
        .get<VolumeData>(url, { signal: controller.signal })
        .then(({ data }) => {
          //console.log('data de :', volume.partition, data);
          volumesCache[url] = data;
          updateState(data);
        })
        .catch(() => {});

      return () => {
        controller.abort();
      };
    //}
  }, [volume, region, instanceDeployment, instanceIp]);

  return (
    <>
      <tr key={volume.volumeId}>
        <td>{volume.volumeId}</td>
        <td>{volume.partition}</td>
        <td>{lmsVersion}</td>
        <td>{volume.client}</td>
        <td>
          {sslStatus === "NO SSL" ? (
            <span className="no-ssl">{sslStatus}</span>
          ) : (
            <>
              <img
                className="ssl-status"
                src={`/images/${sslStatus}-ssl.png`}
                alt=""
              />
              <span className="ssl-icon">{sslStatus}</span>
            </>
          )}
        </td>
        <td>{volume.size}</td>
        <td>{storage}</td>
        {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
          <td>
            <select
                id={`select-resize-${volume.volumeId}`}
                className="form-select"
                onChange={(e) => handleResizeChange(parseInt(e.target.value))}
              >
                  <option key={0} value={0}> Add size</option>
                  <option key={5} value={5}> +5GB</option>
                  <option key={10} value={10}> +10GB</option>
                  <option key={20} value={20}> +20GB</option>
                  <option key={50} value={50}> +50GB</option>
                  <option key={100} value={100}> +100GB</option>
              </select>
          </td>
        ) : null}
        {instanceDeployment === "shared" && (
          <td>
            {volume.partition !== "/dev/xvda" ? (
              <>
                <a
                  className="me-2"
                  href="/#"
                  title="Information"
                  onClick={(e) => {
                    e.preventDefault();
                    onVolumeTags(volume);
                  }}
                >
                  <span className="material-symbols-outlined vol-info">info</span>
                </a>
                {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
                  <>
{/*                     <a
                      className="me-2"
                      title="Delete LMS"
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm(
                            `Please confirm you want to delete the volume ${volume.client} ?`
                          )
                        ) {
                          onDelete(volume.volumeId);
                        }
                      }}
                    >
                      <i className="fa-solid fa-trash-can text-danger"></i>
                    </a> */}
                    <Link
                        className="me-2"
                        title="Delete LMS"
                        to={`delete/${volume.volumeId}/${volume.client}`}
                      >
                        <i className="fa-solid fa-trash-can text-danger"></i>
                    </Link>
                    <a
                      href="/#"
                      className="me-2"
                      title="Backup"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm(
                            `Please confirm you want to create backup to the volume ${volume.client}?`
                          )
                        ) {
                          onBackup(volume.instanceId, volume.domain, volume.client);
                        }
                      }}
                    >
                      <img
                        className="backup-volume"
                        src={`/images/cloud-backup.png`}
                        alt=""
                      />
                    </a>
                    {volume.domain && (
                      <Link
                        title="Actions"
                        to={`operations/${volume.volumeId}/${volume.client}/${volume.domain.replace(/^https?:\/\//, '')}`}
                      >
                        <span className="material-symbols-outlined vol-action">settings</span>
                      </Link>
                    )}
                  </>
                ) : null}
              </>
            ) : null}
          </td>
        )}
      </tr>
    </>
  );
};

function getCertificateStatus(sslDays: string | number): certificateStatusEnum {
  if (sslDays === "NO SSL") {
    return certificateStatusEnum.NA;
  }

  if (typeof sslDays === "number")
    switch (true) {
      case sslDays <= 0:
        return certificateStatusEnum.EXPIRED;
      case sslDays < 30:
        return certificateStatusEnum.RENEW;
      case sslDays >= 30:
        return certificateStatusEnum.VALID;
      default:
        return certificateStatusEnum.NA;
    }

  return certificateStatusEnum.NA;
}

export default VolumeRow;
