import "../css/Chart.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { MonthlyBarChartData, DailyBarChartData, monthNames } from "./types"
import { PureComponent } from "react";

const monthShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec"
];

type CustomTooltipProps = {
  active?: boolean;
  payload?: any[];
  label?: any;
};

type CustomizedAxisTickProps = {
  x?: number;
  y?: number;
  //stroke?: string;
  payload?: {
    value?: any; // ajusta el tipo según tu estructura de datos
  };
};

type CustomBarChartProps = {
  chartData: MonthlyBarChartData[] | DailyBarChartData[];
  dataKeyFromXaxis: string; // 'month' | 'dayOfMonth'
  title: string;
  chartWidth: number; 
  barSize: number;
  monthOfPeriodMonthly?: number;
  tooltipTitle?: string;
  tooltipLabel: string;
  yAxisLabel?: string;
  tickOffsetX?: number; //desplazamiento en el eje X en cada tick del eje
};

function CustomBarChart({ chartData, dataKeyFromXaxis, title, chartWidth, barSize, monthOfPeriodMonthly, tooltipTitle, tooltipLabel, yAxisLabel, tickOffsetX = 0 }: CustomBarChartProps) {
  //console.log('chartData en CustomBarChart', chartData)

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      const unit = yAxisLabel ? yAxisLabel : '';
      if(dataKeyFromXaxis === 'month') {
        const newLabel = monthNames[label - 1]
        return (
          <div className="custom-tooltip">
            <span className="custom-tooltip-label">{newLabel}</span>
            <br />
            <span className="custom-tooltip-bar-name">{tooltipLabel}:</span>
            <span>{payload[0].value.toLocaleString('es-ES', { useGrouping: true })} {unit}</span>
          </div>
        );
      }
      else {
        return (
          <div className="custom-tooltip">
            <span className="custom-tooltip-label">{tooltipTitle} {label}</span>
            <br />
            <span className="custom-tooltip-bar-name">{tooltipLabel}:</span>
            <span>{payload[0].value.toLocaleString('es-ES', { useGrouping: true })} {unit}</span>
          </div>
        );
      }
    }
  
    return null;
  };

/*   const customTickFormatter = (value: any, index: number) => {
    if (dataKeyFromXaxis === 'month') {
      return monthShort[value - 1]; // -1 because monthIndex starts at 1
    } else {
      return `${value}`;
    }
  }; */

  // This component replaces customTickFormatter because there was a delay in the representation of the x-axis ticks and they took on an undefined value for a few seconds
  class CustomizedAxisTick extends PureComponent<CustomizedAxisTickProps>{
    render() {
      const { x, y, payload } = this.props;
      return (
          <text x={x} y={y} fill="black" fontSize={16}>
            <tspan x={x} dx={tickOffsetX} /* dy={15} */>
              { dataKeyFromXaxis === 'month' 
                ? monthShort[payload?.value - 1] 
                : payload?.value
              }
            </tspan>
          </text>
      );
    }
  }

  return (
    <>
      <div className="chart-title">{title}</div>
      <div className="chart-container">
        <BarChart
          width={chartWidth} // 800|500
          height={500}
          data={chartData}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 25
          }}
        >
          <CartesianGrid vertical={false} stroke="black" />
          <XAxis
            dataKey={dataKeyFromXaxis}
            label={{ 
              value: dataKeyFromXaxis === 'dayOfMonth' && monthOfPeriodMonthly 
                      ? monthNames[monthOfPeriodMonthly-1] : '', 
              position : 'insideBottomRight', offset: 0, dy: 8 }}
            tickMargin={15}
            tick={<CustomizedAxisTick />}
            // tickFormatter = { customTickFormatter }
          />
          <YAxis
            axisLine={false}
            tick={{ fill: "black", fontSize: 16 }}
            tickMargin={8}
            tickFormatter={tick => {
              //format the thousandths indicator on the numbers
              return tick.toLocaleString('es-ES', { useGrouping: true });
            }}
            label={yAxisLabel ? { value: yAxisLabel, angle: 0, position: 'insideBottom', offset: -30, fill: 'black', fontWeight: 'bold' } : ''}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{fill: 'transparent'/* , stroke: '#4caa49' */}}
          />
            <Bar
              dataKey="amount"
              stackId="a"
              barSize={barSize} // 40|50
              fill="#4caa49"
            />

        </BarChart>
      </div>
    </>
  );
}

export default CustomBarChart;