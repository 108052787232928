import { useEffect, useState } from "react";
import {
  useNavigate,
  useNavigation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import DBSnapshotTable from "./DBSnapshotTable";
import { api } from "../../api";
import { AxiosError } from "axios";

type DBSnapshotType = {
  dbSnapshotId: string;
  dbSnapshotDate: string;
  dbInstanceId: string;
};

const DBInstanceSnapshotsModal = () => {
  const params = useParams();
  const dbInstanceId = params.dbInstanceId;
  const [dbSnapshotList, setDBSnapshotList] = useState<DBSnapshotType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const region = useOutletContext();
  const [isCreatingPage, setIsCreatingPage] = useState<boolean>(true);

  useEffect(() => {
    console.log("useEffect de DBInstanceSnapshotsModal: ", region);

    if (isCreatingPage) {
      setIsLoading(true); //show loading element

      // Obteniendo dbSnapshots
      api
        .get(`/dbSnapshots/${dbInstanceId}?region=${region}`)
        .then(({ data }) => {
          console.log("data recibida en useEffect de dbSnapshots: ", data);
          if (data.success) {
            setDBSnapshotList(data.dbSnapshots);
          }
          setIsLoading(false); //hide loading element
        })
        .catch((err) => {
          if (err instanceof AxiosError && err.response) {
            setError(true);
            setErrorMessage(err.response.data.message);
          } else if (err.error && err.message) {
            setError(true);
            setErrorMessage(err.message);
          } else {
            setError(true);
            setErrorMessage(
              "An error has occurred. Failed to load DB Snapshots."
            );
          }
          setIsLoading(false); //hide loading element
        });

      setIsCreatingPage(false);
    }
  }, [isCreatingPage, dbInstanceId, region]);

  return (
    <>
      <div className="modal-overlay">
        <div
          className={
            isLoading || navigation.state === "submitting" ? "loading" : ""
          }
        ></div>
        <div className="modal-actions information-dbsnap">
          <button
            className="close-modal-button"
            onClick={() => {
              navigate(-1);
            }}
          >
            x
          </button>
          <h4 className="title-modal-information">DBSnapshots</h4>
          <div className="modal-actions-body">
            <div className="section-modal-dbsnap">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>{dbInstanceId}</h5>
                </div>
              </div>
              <div className="section-content volumes">
                {error && errorMessage ? (
                  <div className="pathMessage">
                    <p>{errorMessage}</p>
                  </div>
                ) : (
                  <DBSnapshotTable dbSnapshots={dbSnapshotList} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DBInstanceSnapshotsModal;
