import { ActionFunctionArgs } from "react-router-dom";

const loader = async ({ params }: ActionFunctionArgs) => {
  //const region = window.localStorage.getItem("moodle-console-region");
  const instanceId = params.instanceId;
  const instanceIp = params.instanceIp;
  const instanceName = params.instanceName;
  const instanceDeployment = params.instanceDeployment;
  const instanceVersion = params.instanceVersion || "";

  return {
    instanceId: instanceId,
    instanceIp: instanceIp,
    instanceName: instanceName,
    instanceDeployment: instanceDeployment,
    instanceVersion: instanceVersion
  };
};

export default loader;
