import { FC } from "react";
import { Form } from "react-router-dom";
import { useAuth, ROLE } from "../../auth/AuthContext";

interface InformationProps {
  suspensionDateState: {
    suspensionDate: string;
    setSuspensionDate: (suspensionDate: string) => void;
  };
  contractStartDateState: {
    contractStartDate: string;
    setContractStartDate: (contractStartDate: string) => void;
  };
  purchasedStorageState: {
    purchasedStorage: string;
    setPurchasedStorage: (purchasedStorage: string) => void;
  };
  allowedUsersState: {
    allowedUsers: string;
    setAllowedUsers: (allowedUsers: string) => void;
  };
  supportHoursState: {
    supportHours: string;
    setSupportHours: (supportHours: string) => void;
  };
  descriptionState: {
    description: string;
    newDescription: string;
    setNewDescription: (newDescription: string) => void;
  };
  branchState: {
    branch: string;
    setBranch: (branch: string) => void;
  };
  clientState: {
    client: string;
    setClient: (client: string) => void;
  };
  onSubmitUpdate: (combinedDescription: string) => void;
  totaraUpgradeKey: string;
  concurrency: string;
  domain: string;
  lms: string;
  version: string;
  instanceDeployment: string;
  dbServerType?: string;
}

const Information: FC<InformationProps> = ({
  suspensionDateState: { suspensionDate, setSuspensionDate },
  contractStartDateState: { contractStartDate, setContractStartDate },
  purchasedStorageState: { purchasedStorage, setPurchasedStorage },
  allowedUsersState: { allowedUsers, setAllowedUsers },
  supportHoursState: { supportHours, setSupportHours },
  descriptionState: { description, newDescription, setNewDescription },
  clientState: { client, setClient },
  branchState: { branch, setBranch },
  onSubmitUpdate,
  totaraUpgradeKey,
  concurrency,
  domain,
  lms,
  version,
  instanceDeployment,
  dbServerType
}) => {

  const { canUser } = useAuth();
  console.log('instanceDeployment:', instanceDeployment)

  const handleBranchChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBranch = event.target.value;
    if (selectedBranch === 'CR' || selectedBranch === 'MX') {
      setBranch(selectedBranch);
    }
    else {
      alert('The selected branch is not valid. Please select a valid branch')
    }
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentDescription = e.target.value;
    const originalDescriptionLength = description.length;
    // console.log('currentDescription', currentDescription);
    // console.log('originalDescriptionLength', originalDescriptionLength);

    // Check if the user is trying to modify the original Description
    if (currentDescription.startsWith(description)) {
      // If not, update the new part of the Description
      const updatedNewDescription = currentDescription.slice(originalDescriptionLength);
      //console.log('updatedNewDescription', updatedNewDescription);

      // Update the state with the new part of the Description
      setNewDescription(updatedNewDescription);
    } else {
      // If the user is trying to modify the original Description, prevent it
      e.target.value = description + newDescription;
    }
};

  // Combine the original Description with the new part of the Description
  const combinedDescription = description + newDescription;

  return (
    <Form
      className="instance-data"
      onSubmit={() => {
        onSubmitUpdate(combinedDescription);
      }}
    >
      <div className="row">
        <div className="col-sm-3">
          <label className="form-label" htmlFor="startDateInput">Start Date</label>
          <input className="form-control" id="startDateInput" name="startDate" type="date"
            value={contractStartDate}
            onChange={(e) => setContractStartDate(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <label className="form-label" htmlFor="suspensionDateInput">Suspension Date</label>
          <input className="form-control" id="suspensionDateInput" name="suspensionDate" type="date" 
            value={suspensionDate}
            onChange={(e) => setSuspensionDate(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <label className="form-label" htmlFor="purchasedStorageInput">Purchased Storage</label>
          <input className="form-control" id="purchasedStorageInput" name="purchasedStorage" type="text" 
            value={purchasedStorage}
            onChange={(e) => setPurchasedStorage(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <label className="form-label" htmlFor="allowedUsersInput">Allowed Users</label>
          <input className="form-control" id="allowedUsersInput" name="allowedUsers" type="text" 
            value={allowedUsers}
            onChange={(e) => setAllowedUsers(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-3">
          <label className="form-label" htmlFor="supportHoursInput">Contracted Support Hours</label>
          <input className="form-control" id="supportHoursInput" name="supportHours" type="text" 
            value={supportHours}
            onChange={(e) => setSupportHours(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <label className="form-label" htmlFor="lmsInput">LMS</label>
          <input className="form-control read-only" id="lmsInput" name="lms" type="text" 
            readOnly={true}
            defaultValue={lms}
          />
        </div>
        <div className="col-sm-3">
          <label className="form-label" htmlFor="versionInput">Version</label>
          <input className="form-control read-only" id="versionInput" name="version" type="text" 
            readOnly={true}
            defaultValue={version}
          />
        </div>
        <div className="col-sm-3">
          <label className="form-label" htmlFor="concurrencyInput">Active Users</label>
          <input className="form-control read-only" id="concurrencyInput" name="concurrency" type="text" 
            readOnly={true}
            defaultValue={concurrency}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-3">
          <label className="form-label" htmlFor="domainInput">Instance's URL</label>
          <input className="form-control read-only" id="domainInput" name="domain" type="text" 
            readOnly={true} 
            value={domain}
          />
        </div>
        <div className="col-sm-3">
          <label className="form-label" htmlFor="branchSelect">Branch</label>
          <select className="form-select" name="branch" id="branchSelect" 
            value={branch} 
            onChange={(e) => handleBranchChange(e)}
          >
            <option value="">Select an option</option>
            <option key="MX" value="MX">México</option>
            <option key="CR" value="CR">Costa Rica</option>
          </select>
        </div>
        <div className="col-sm-3">
          <label className="form-label" htmlFor="clientInput">Client</label>
          <input className="form-control" id="clientInput" name="client" type="text"
            value={client}
            onChange={(e) => setClient(e.target.value)}
          />
        </div>
        {instanceDeployment === 'dedicated' && (
          <div className="col-sm-3">
            <label className="form-label" htmlFor="dbServerTypeInput">DB Server Type</label>
            <input className="form-control read-only" id="dbServerTypeInput" name="dbServerType" type="text"
              readOnly={true}
              defaultValue={dbServerType}
            />
          </div>
        )}
      </div>
      <div className="row mt-4">
        {totaraUpgradeKey && lms === 'Totara' && (
          <div className="col-sm-3">
            <label className="form-label" htmlFor="upgradeKeyInput">Totara Upgrade Key</label>
            <input className="form-control read-only" id="upgradeKeyInput" name="upgradeKey" type="text"
              readOnly={true}
              defaultValue={totaraUpgradeKey}
            />
          </div>
        )}
      </div>
      <div className="row mt-4 mb-4">
        <div className="col-12">
          <label className="form-label" htmlFor="descriptionInput">Description</label>
          <textarea
            id="descriptionInput"
            name="descripcion"
            className="form-control text-area-description"
            value={combinedDescription}
            onChange={handleDescriptionChange}
            maxLength={255}
            rows={3}
          />
        </div>
      </div>
      
      {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
        <div className="row justify-content-end">
          <div className="col-sm-2">
            <button type="submit" className="btn btn-instance-data">
              Update
            </button>
          </div>
        </div>
      ) : null}
    </Form>
  );
};

export default Information;
